import { useState } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Button,  Modal } from "@circle/kip-components";

import { EventForm } from "./EventForm";

const EventButton = ({ ...props }) => {
    const { trigger }       = useTrigger();
    const { translate }     = useTranslation();
    const [modal, setModal] = useState(false);
    const { plant }         = useBranch({
        plant: ["selectedPlant"]
    });

    const onClose = () => {
        setModal(!modal);
    };

    const onSubmit = event => {
        trigger("createEvent", event);

        onClose();
    };

    return (
        <>
            <div className={!props.isNested ? "header-button" : "minWidth"}>
                <Button onClick={() => setModal(!modal)}>{translate("overview.new.event")}</Button>
            </div>

            { plant &&
                <Modal
                    title={translate("title.record.event")}
                    isOpen={modal}
                    isCloseable={false}
                    onClose={onClose}
                    className="event-modal"
                >
                    <div id="event-form" className="flex-column">
                        <EventForm
                            isEditable={true}
                            onClose={onClose}
                            selectedPlant={plant}
                            onSubmit={onSubmit}
                            plantId={plant.id}
                        />
                    </div>
                </Modal>
            }
        </>
    );
};

EventButton.propTypes = {
    isNested: PropTypes.bool
};

export { EventButton };
