import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { HeaderPieGraph } from "./headerPieGraph/HeaderPieGraph";
import { ClustersTable } from "./clustersTable/ClustersTable";
import { LineGraphWidget } from "../widgets/lineGraphWidget";
import { EventButton } from "../EventButton";
import { PlantOverviewHeader } from "../generic/plantOverviewHeader/PlantOverviewHeader";
import { BusyIndicator } from "../BusyIndicator";

import styles from "./runtimeOverview.module.scss";
import { AvailabilityGraph } from "./clusterRuntimeOverview/clusterStatisticsContent/availabilityGraph/AvailabilityGraph";

const RuntimeOverview = () => {
    const params                         = useParams();
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();

    const { plant, licensedPlants, options, content, headerPieGraph, isLoading } = useBranch({
        plant:          ["selectedPlant"],
        licensedPlants: ["licensedPlants"],
        options:        ["queryOptions"],
        content:        ["runtimeOverview"],
        headerPieGraph: ["headerPieGraph"],
        isLoading:      ["loadingViews", "runtimeOverview"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   licensedPlants,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    useEffect(() => {
        trigger("applyPlant", params.plantId);
        trigger("resetCluster");
        trigger("resetPlantElement");
    }, [params.plantId]);


    useEffect(() => {
        trigger("fetchRuntimeOverview", { plantId: params.plantId });

        return () => trigger("clearMemory");
    }, []);

    return (
        <div className={styles.runtimeContainer}>
            {
                init &&
                <>
                    <div className={styles.header}>
                        <PlantOverviewHeader
                            plant={plant}
                            options={options}
                            currentLanguage={currentLanguage}
                            translate={translate}
                        />
                        <EventButton />
                    </div>
                    {
                        isLoading &&
                        <BusyIndicator />
                    }
                    <div className={styles.runtimeContent}>
                        {
                            !isLoading &&
                            <HeaderPieGraph
                                {...headerPieGraph}
                                linkDirection={"runtime"}
                            />
                        }

                        <div className={styles.headline}>
                            <h6 className={styles.headline}>
                                <b>{translate("disruption.time")}</b>{` ${translate("overview.all.system")}`}
                            </h6>
                        </div>
                        <div className={styles.contentContainer}>
                            <ClustersTable />
                            <div className={styles.graphStyle}>
                                <LineGraphWidget
                                    squareLineDataKey={"technical"}
                                    circleLineDataKey={"organizational"}
                                    squareLineColor={"#EB6060"}
                                    circleLineColor={"#F3C173"}
                                    data={content.interruptions ?? []}
                                    isLoading={isLoading}
                                    mainHeaderTitle={translate("detail.history")}
                                    secondaryHeaderTitle={translate("detail.cluster.graph.interruption.in.min")}
                                    translate={translate}
                                    XAxisKey={"name"}
                                />
                                <AvailabilityGraph
                                    isLoading={isLoading}
                                    data={content.availability ?? []}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export { RuntimeOverview };
