import PropTypes from "prop-types";
import { LoadingSpinner } from "@circle/kip-components";

const BusyIndicator = ({ withContainer = false }) => {
    return (
        <LoadingSpinner _threshhold={500} _mode={withContainer ? "global" : "inline"} />
    );
};

BusyIndicator.propTypes = {
    withContainer: PropTypes.bool
};


export { BusyIndicator };
