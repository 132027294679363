import { Routes, Route } from "react-router-dom";
import { RuntimeOverview } from "../RuntimeOverview";
import { ClusterRuntimeOverview } from "../clusterRuntimeOverview/ClusterRuntimeOverview";

const RuntimeRouter = () => {
    return (
        <Routes>
            <Route path="/:plantId/*" element={<RuntimeOverview/>} />
            <Route path="/:plantId/:clusterId/:plantElementId/*" element={<ClusterRuntimeOverview />} />
        </Routes>
    );
};

export { RuntimeRouter };
