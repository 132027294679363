import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";

import { PlantOverviewHeader } from "../generic/plantOverviewHeader";
import { EventButton } from "../EventButton";
import { EventsHeader } from "./eventsHeader";
import { EventsStatisticsTable } from "./eventsStatisticsTable";
import { LineGraphWidget } from "../widgets/lineGraphWidget";

import styles from "./eventsOverview.module.scss";

const EventsOverview = () => {
    const { trigger }                    = useTrigger();
    const params                         = useParams();
    const { translate, currentLanguage } = useTranslation();

    const { plant, options, graphData, isGraphLoading, licensedPlants } = useBranch({
        plant:          ["selectedPlant"],
        options:        ["queryOptions"],
        graphData:      ["eventsGraph"],
        isGraphLoading: ["loadingViews", "eventsGraph"],
        licensedPlants: ["fullPlants"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   licensedPlants,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    useEffect(() => {
        trigger("applyPlant", params.plantId);
        trigger("fetchEventsGraph", { plantId: params.plantId });
        trigger("fetchEventsContentOverview", { plantId: params.plantId });
    }, []);

    return (
        <>
            {
                init &&
                <>
                    <div className={styles.header}>
                        <PlantOverviewHeader
                            plant={plant}
                            options={options}
                            currentLanguage={currentLanguage}
                            translate={translate}
                        />
                        <EventButton />
                    </div>
                    <EventsHeader />
                    <div className={styles.headline}>
                        <h4>
                            {translate("dashboard.widgets.events")}
                            <span className={styles.subHeadline}>{" "}{translate("overview.all.system")}</span>
                        </h4>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.item}>
                            <EventsStatisticsTable />
                        </div>
                        <div className={styles.item}>
                            <LineGraphWidget
                                graphHeight={245}
                                isLoading={isGraphLoading}
                                data={graphData ?? []}
                                mainHeaderTitle={translate("detail.history")}
                                secondaryHeaderTitle={translate("events.in.period")}
                                circleLineDataKey={"amount"}
                                isNoUnit={true}
                                translate={translate}
                            />
                        </div>
                    </div>
                </>
            }
        </>

    );
};

export { EventsOverview };
