import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { numberFormatter } from "../../../../../helper/numberFormatter";
import { BusyIndicator } from "../../../../BusyIndicator";

import styles from "./productivityCard.module.scss";

const ProductivityCard = ({ ...props }) => { // eslint-disable-line complexity
    const { translate } = useTranslation();

    const isContentAvailable = props.data.produced?.absolute?.target >= 1 ||
        props.data.produced?.absolute?.current >= 1 ||
        props.data.sortedOut >= 1 ||
        props.data.produced?.perMinute.current >= 1 ||
        props.data.produced?.avgProcessingTime >= 1;

    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerTitle}>
                <span><b>{translate("detail.cluster.productivity.overview")}</b></span>
            </div>
            {
                props.isLoading &&
                <BusyIndicator />
            }
            {
                (!props.isLoading && isContentAvailable) &&
                <div className={styles.contentContainer}>
                    <div className={styles.itemContainer}>
                        <span><b>{props.data.produced.absolute.target > 0 ? numberFormatter(props.data.produced.absolute.target, 99999999) : "-"}</b></span>
                        <small className={styles.itemDescription}>{translate("Soll-Leistung")}</small>
                    </div>
                    <div className={styles.itemContainer}>
                        <span><b>{numberFormatter(props.data.produced.absolute.current, 99999999)}</b></span>
                        <small className={styles.itemDescription}>{translate("produzierte Teile")}</small>
                    </div>
                    <div className={styles.itemContainer}>
                        <span><b>{numberFormatter(props.data.sortedOut, 99999999)}</b></span>
                        <small className={styles.itemDescription}>{translate("Ausschussteile")}</small>
                    </div>
                    <div className={styles.itemContainer}>
                        <span>ø<b>{numberFormatter(props.data.produced.perMinute.current, 999999, true)}</b></span>
                        <small className={styles.itemDescription}>{translate("Teile pro Minute")}</small>
                    </div>
                    <div className={styles.itemContainer}>
                        <span>ø<b>{numberFormatter(props.data.produced.avgProcessingTime, 999999)}{" "}{translate("overview.seconds.short")}</b></span>
                        <small className={styles.itemDescription}>{translate("Prozesszeit")}</small>
                    </div>
                </div>
            }
            {
                (!props.isLoading && !isContentAvailable) &&
                <div>
                    <span>{translate("overview.no.content")}</span>
                </div>
            }
        </div>
    );
};

ProductivityCard.propTypes = {
    data:         PropTypes.object,
    isLoading:    PropTypes.bool,
    runtimeCheck: PropTypes.bool
};

export { ProductivityCard };
