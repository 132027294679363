import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ClusterGraphsContent } from "../../runtimeOverview/clusterRuntimeOverview/clusterGraphsContent";
import { ClusterStatisticsContent } from "../../runtimeOverview/clusterRuntimeOverview/clusterStatisticsContent";
import { HeaderPieGraph } from "../../runtimeOverview/headerPieGraph/HeaderPieGraph";
import { ClusterOverviewHeader } from "../../generic/clusterOverviewHeader/ClusterOverviewHeader";
import { useQueryString } from "../../../hooks/querystring";
import { useQuery } from "../../../hooks/query";
import { BusyIndicator } from "../../BusyIndicator";
import { getKind, getType } from "../../../helper/cluster";

import styles from "./clusterFavoriteView.module.scss";

const ClusterFavoriteView = () => { // eslint-disable-line complexity
    const params                         = useParams();
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();

    const { options, content, headerPieGraph, plants, isLoading, plant, selectedCluster, selectedPlantElement } = useBranch({
        options:              ["queryOptions"],
        content:              ["clusterFavoriteView"],
        headerPieGraph:       ["headerPieGraph"],
        plants:               ["fullPlants"],
        isLoading:            ["loadingViews", "clusterRuntimeOverview"],
        plant:                ["selectedPlant"],
        selectedCluster:      ["selectedCluster"],
        selectedPlantElement: ["selectedPlantElement"]
    });

    const [filter] = useQueryString({
        plants:   plants,
        options:  options,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    useEffect(() => {
        trigger("applyPlant", params.plantId);
        trigger("applyCluster", params.plantId, params.clusterId);
        trigger("applyPlantElement", params.plantElementId);
    }, []);

    useEffect(() => {
        trigger("fetchClusterRuntimeOverview", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId });
    }, [options.calendar, options.range]);

    useEffect(() => {
        trigger("fetchClusterRuntimeOverview", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId });
    }, []);

    const kind = getKind(params);
    const type = getType(kind, selectedCluster, selectedPlantElement);

    return (
        <>
            {
                init &&
                <>
                    <ClusterOverviewHeader cluster={plant} translate={translate} currentLanguage={currentLanguage} options={options} />
                    {
                        isLoading &&
                        <BusyIndicator />
                    }
                    {
                        !isLoading && type.includes("States") &&
                        <HeaderPieGraph {...headerPieGraph} />
                    }
                    <div className={styles.headline}>
                        <h6 className={styles.headline}>
                            <b>{translate("detail.cluster.overview")}</b>
                        </h6>
                    </div>
                    {
                        !type.includes("States") &&
                        <div className={styles.margin2rem}/>
                    }
                    <div className={styles.contentContainer}>
                        <ClusterGraphsContent
                            type={type}
                            interruptions={content.interruptions ?? []}
                            performance={content.performanceGraph ?? []}
                            isLoading={isLoading}
                        />
                        <ClusterStatisticsContent
                            type={type}
                            isFavorite={true}
                            performance={content.performance ?? {}}
                            performanceGraph={content.speedCourse ?? []}
                            interruptions={content.aggregatedInterruptions ?? []}
                            isLoading={isLoading}
                        />
                    </div>
                </>
            }
        </>
    );
};

export { ClusterFavoriteView };
