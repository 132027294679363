
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { BusyIndicator } from "../../BusyIndicator";

import styles from "./eventsHeader.module.scss";

const EventsHeader = () => {
    const { translate } = useTranslation();

    const { data, isLoading }  = useBranch({
        data:      ["eventsContent", "overview"],
        isLoading: ["loadingViews", "eventsContent"]
    });

    return (
        <div className={styles.container}>
            { isLoading && <BusyIndicator /> }
            { (data && !isLoading) && <>
                <div className={styles.header}><b>{translate("dashboard.widgets.events")}</b>{" "}<span>{translate("system.headline")}</span></div>
                <div className={styles.contentContainer}>
                    <div className={styles.item}>
                        <b className={styles.header}>{data.countModified}</b>
                        <small className={styles.subTitle}>{translate("events.total.count")}</small>
                    </div>
                    <div className={styles.item}>
                        <b className={styles.header}>{data.maintenanceModified}</b>
                        <small className={styles.subTitle}>{translate("maintenance.headline")}</small>
                    </div>
                    <div className={styles.item}>
                        <b className={styles.header}>{data.setupModified}</b>
                        <small className={styles.subTitle}>{translate("setup.headline")}</small>
                    </div>
                    <div className={styles.item}>
                        <b className={styles.header}>{data.otherModified}</b>
                        <small className={styles.subTitle}>{translate("overview.various")}</small>
                    </div>
                    <div className={styles.item}>
                        <b className={styles.header}>{data.durationModified} {translate("overview.minutes.short")}</b>
                        <small className={styles.subTitle}>{translate("events.total.duration")}</small>
                    </div>
                </div>
            </> }
        </div>
    );
};

export { EventsHeader };
