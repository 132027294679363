import PropTypes from "prop-types";

import styles from "./plantCard.module.scss";

const PlantCard = ({ ...props }) => {
    return (
        <div className={styles.plant}>
            {
                props.plant &&
                <div className={styles.logo}>
                    <img src={props.plant?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
                </div>
            }
            <div>
                <div className={styles.plantName}>
                    <span>{ props.translate(props.plant?.name) }</span>
                </div>
                <span className={styles.manufacturer}>{ props.plant?.manufacturer?.name }</span>
            </div>
        </div>
    );
};

PlantCard.propTypes = {
    plant:     PropTypes.object,
    translate: PropTypes.func
};

export { PlantCard };
