import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import mammoth from "mammoth";
import styles from "./file-viewer.module.scss";

const FileViewer = ({ ...props }) => {
    const [file, setFile] = useState(null);

    useEffect(() => {
        const func = async() => {
            if(props.file.type !== "docx") return;

            const response = await fetch(props.file.url);
            const buffer = await response.arrayBuffer();
            const { value } = await mammoth.convertToHtml({ arrayBuffer: buffer }, { includeDefaultStyleMap: true });

            setFile(value);
        };

        func();
    }, [props.file]);

    return (
        <>
            {
                props.file.isFile &&
                <div className={styles.container}>
                    <div className={styles.viewerContainer} id="pg-viewer">
                        {
                            props.file.type === "document" && !props.fullScreen &&
                                <iframe className={styles.documentContainer} src={props.file.url} frameBorder="0" />
                        }
                        {
                            props.file.type === "document" && props.fullScreen &&
                                <iframe className={styles.documentFullScreenContainer} src={props.file.url} frameBorder="0" />
                        }
                        {
                            props.file.type === "video" &&
                            <div className={styles.driverView}>
                                <div className={styles.videoContainer}>
                                    <video controls className={styles.video} frameBorder="0" type="video/mp4" src={props.file.url}>Video playback is not supported by your browser.</video>
                                </div>
                            </div>
                        }
                        {
                            props.file.type === "image" &&
                            <div className={styles.photoContainer} id="pg-photo-container">
                                <img className={styles.image} src={props.file.url} />
                            </div>
                        }
                        {
                            props.file.type === "docx" && file &&
                            <div id="docx">
                                <div className={styles.documentContainer} dangerouslySetInnerHTML={{ __html: file }} />
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

FileViewer.propTypes = {
    file:       PropTypes.object,
    fullScreen: PropTypes.bool
};

export { FileViewer };
