import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { Link } from "react-router-dom";
import { Icon } from "@circle/kip-components";

import { HorizontalBarGraph } from "../../generic/hotizontalBarGraph";

import styles from "./performanceHeader.module.scss";

const PerformanceHeader = ({ ...props }) => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const { plant }     = useBranch({
        plant: ["selectedPlant"]
    });

    const graphData = {
        producedParts: props.data.produced?.absolute.current,
        target:        props.data.produced?.absolute.target,
        performance:   props.data.performance?.currently
    };

    const references = props.references.map(x => translate(x.name)).join(", ");

    return (
        <Link to={`/performance/${plant?.id}/null/null/${window.location.search}`} className={styles.titleLink}>
            <div className={styles.container}>
                <div className={styles.section1}>
                    <div className={styles.header}>
                        <b>{translate("overview.performance")}</b>
                        <span> {translate("system.headline")}</span>
                    </div>
                    <div className={styles.contentContainer}>
                        <div className={styles.item}>
                            <div className={styles.mixedHeaderContainer}>
                                {props.data.produced?.absolute?.target > 0 ?
                                    <>
                                        <h6 className={styles.headline}>{props.data.performance?.currently}</h6>
                                        <span>%</span>
                                    </> :
                                    <span>-</span>
                                }
                            </div>
                            <div className={styles.description}>
                                <small>{translate("overview.performance")}</small>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <HorizontalBarGraph data={graphData ?? {}} />
                        </div>
                        <div className={styles.item}>
                            <div className={styles.mixedHeaderContainer}>
                                <b>{props.data.produced?.absolute.currentModified}</b>
                                {
                                    props.data.produced?.absolute.target > 0 &&
                                    <>
                                        <span>/</span>
                                        <small className={styles.target}>{props.data.produced?.absolute.targetModified}</small>
                                    </>
                                }
                            </div>
                            <div className={styles.description}><small>{translate("overview.produced.parts")}</small></div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.mixedHeaderContainer}><span>ø</span><b>{props.data.produced?.perMinute.currentModified}</b></div>
                            <div className={styles.description}><small>{translate("performance.minutes")}</small></div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.mixedHeaderContainer}><b>{props.data.produced?.cycleTimeModified}s</b></div>
                            <div className={styles.description}><small>{translate("title.cycle.time.avg")}</small></div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.mixedHeaderContainer}><b>{props.data?.sortedOutModified}</b></div>
                            <div className={styles.description}><small>{translate("sorted.out.parts")}</small></div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.mixedHeaderContainer}><b>{references}</b></div>
                            <div className={styles.referenceList}><small>{translate("detail.reference.performance")}</small></div>
                        </div>
                    </div>
                </div>
                <div className={styles.section2}>
                    <Icon className={styles.chevron} _icon="ChevronRight" />
                </div>
            </div>
        </Link>
    );
};

PerformanceHeader.propTypes = {
    data:       PropTypes.object,
    references: PropTypes.arrayOf(PropTypes.object)
};

export { PerformanceHeader };
