import PropTypes from "prop-types";

const renderCustomizedLabel = props => {
    const { x, y, width, height, value, color, fontWeight, isLine, fontSize } = props; // eslint-disable-line @typescript-eslint/no-unused-vars
    const radius = 10;

    const renderValue = value === 0 ? null : value;

    return (
        <g>
            <text fontSize={fontSize} x={!isLine ? x + width / 2 : x} y={y - radius} fill={color} fontWeight={fontWeight} textAnchor="middle" dominantBaseline="middle">
                {renderValue}
            </text>
        </g>
    );
};

renderCustomizedLabel.propTypes = {
    x:          PropTypes.number,
    y:          PropTypes.number,
    width:      PropTypes.number,
    height:     PropTypes.number,
    value:      PropTypes.number,
    color:      PropTypes.string,
    fontWeight: PropTypes.string,
    isLine:     PropTypes.bool
};

export { renderCustomizedLabel };
