import { Routes, Route } from "react-router-dom";
import { EventsOverview } from "../EventsOverview";


const EventsRouter = () => {
    return (
        <Routes>
            <Route path="/:plantId/*" element={<EventsOverview />} />
        </Routes>
    );
};

export { EventsRouter };
