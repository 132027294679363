import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { Icon } from "@circle/kip-components";

import { usePrevious } from "../../../../hooks/previous";
import { InfiniteList } from "../../../generic/InfiniteList";

import styles from "./frequencyTable.module.scss";

const FrequencyTable = () => {
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const params        = useParams();

    const { content, metadata, options, events, sorting } = useBranch({
        content:       ["eventsContent", "list"],
        metadata:      ["metadata", "eventsContent"],
        options:       ["queryOptions"],
        events:        ["events"],
        sorting:       ["sortings", "events"],
        selectedPlant: ["selectedPlant"]
    });

    const optionsPrev = usePrevious(options);

    const onFetch = () => {
        trigger("fetchEventGroups", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: false });
    };

    const onSort = val => {
        const inter = sorting.isOrderAsc ? "desc" : "asc";
        const order = val !== sorting.property ? "desc" : inter;

        trigger("fetchEventGroups", { plantId: params.plantId, orderKey: val, order, reset: true });
        trigger("setSorting", "events", val);
    };

    useEffect(() => {
        trigger("setSorting", "events", "count");
        trigger("fetchEventGroups", { plantId: params.plantId, orderKey: "count", order: "desc", reset: true });
    }, []);

    useEffect(() => {
        if(!events || !options || !optionsPrev) return;

        trigger("fetchEventGroups", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options, events]);

    return (
        <div className={styles.dataTableContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.headerItem} onClick={() => onSort("name")}>
                    <span>{ (translate("element.name.header")).toUpperCase() }</span>
                    {
                        sorting.property === "name" &&
                        <div className={resolveClassNames("pr-4", styles.rowHeader)}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("category")}>
                    <span>{ (translate("events.category")).toUpperCase() }</span>
                    {
                        sorting.property === "category" &&
                        <div className={resolveClassNames("pr-4", styles.rowHeader)}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("count")}>
                    <span>{ (translate("dashboard.widgets.produced.amount")).toUpperCase() }</span>
                    {
                        sorting.property === "count" &&
                        <div className={resolveClassNames("pr-4", styles.rowHeader)}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
            </div>
            {
                (content ?? []).length === 0 &&
                <div>
                    <span>{ translate("datatable.empty") }</span>
                </div>
            }
            <InfiniteList
                className={styles.infinitelist}
                metadata={metadata}
                onFetch={onFetch}
                element="div"
                placeholder={<span />}
                loader={() => <span>Loading...</span>}
            >
                {
                    (content ?? []).map((x, idx) => (
                        <div className={styles.row} key={idx}>
                            <div className={styles.item}>
                                <span className={styles.clamped}><b>{ translate(x.name) }</b></span>
                            </div>
                            <div className={styles.item}>
                                <span className={styles.clamped}><b>{ translate(x.category) }</b></span>
                            </div>
                            <div className={styles.item}>
                                <span>{x.countModified}</span>
                            </div>
                        </div>
                    ))
                }
            </InfiniteList>
        </div>
    );
};

export { FrequencyTable };
