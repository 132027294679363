export const numberFormatter = (value: number, maxValue: number, formatAsDouble: boolean): string => {
    const options = formatAsDouble ? { minimumFractionDigits: 1, maximumFractionDigits: 1 } : {};
    const formatter = new Intl.NumberFormat("de-DE", options);
    const formattedValue = formatter.format(value);
    const formattedMax = formatter.format(maxValue);

    if(value > maxValue)
        return `${formattedMax}+`;


    if(formattedValue.endsWith(",0"))
        return formattedValue.slice(0, -2);


    return formattedValue;
};
