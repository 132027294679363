import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Button, TextInput } from "@circle/kip-components";
import styles from "./calendar.module.scss";
import { DateInput } from "./generic/dateInput";
import { unifyDateInput } from "../helper/date";


const Calendar = ({ ...props }) => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const [value, setValue] = useState(props.value);
    const [disabledTime, setDisabledTime] = useState(true);
    const [cacheFrom, setCacheFrom] = useState();
    const [cacheUntil, setCacheUntil] = useState(null);

    const onSubmit = e => {
        e.stopPropagation();

        if(!value.from || !value.until) return;

        props.onChange(value);
    };


    const onChangeFrom = val => {
        const { from, until } = value; //eslint-disable-line

        if(!props.isModalView) {
            setValue({ from: val, until });
            return props.onChange({ from: val, until });
        }

        return setValue({ from: val, until });
    };

    const onChangeUntilModal = val => {
        const { from, until } = value; //eslint-disable-line

        if(disabledTime) return setValue({ from, until: val });

        return setValue({ from, until: new Date(new Date(val).setHours(until.getHours(), until.getMinutes(), until.getSeconds(), until.getMilliseconds())) });
    };

    const onChangeUntil = val => {
        const { from, until } = value; //eslint-disable-line

        if(disabledTime) {
            setValue({ from, until: val });
            return props.onChange({ from, until: val });
        }
        setValue({ from, until: new Date(new Date(val).setHours(until.getHours(), until.getMinutes(), until.getSeconds(), until.getMilliseconds())) });
        return props.onChange({ from, until: new Date(new Date(val).setHours(until.getHours(), until.getMinutes(), until.getSeconds(), until.getMilliseconds())) });
    };

    const onTimeFrom = e => {
        const { from, until } = value; //eslint-disable-line
        const startTime       = !isNaN(new Date(from).getTime()) ? new Date(from) : new Date(cacheFrom);

        startTime.setHours(e.target.value.split(":")[0], e.target.value.split(":")[1]);

        if(isNaN(startTime.getTime()) && !isNaN(new Date(from).getTime())) setCacheFrom(from);

        const timeFrom = unifyDateInput(startTime);

        if(!props.isModalView)
            props.onChange({ from: timeFrom, until });

        return setValue({ from: timeFrom, until });
    };

    const onTimeUntil = e => {
        const { from, until } = value; //eslint-disable-line
        const endTime       = !isNaN(new Date(until).getTime()) ? new Date(until) : new Date(cacheUntil);

        endTime.setHours(e.target.value.split(":")[0], e.target.value.split(":")[1]);

        if(isNaN(endTime.getTime()) && !isNaN(new Date(until).getTime())) setCacheUntil(until);

        const timeUntil = unifyDateInput(endTime);

        if(!props.isModalView)
            props.onChange({ from, until: timeUntil });

        return setValue({ from, until: timeUntil });
    };

    const onClose = e => {
        e.stopPropagation();

        setValue(props.value);

        props.onCancel();
    };

    const onToggleTime = () => {
        if(!disabledTime) setValue({ from: new Date(new Date(value.from).setHours(0, 0, 0, 0)), until: new Date(new Date(value.until).setHours(23, 59, 59, 999)) });
        setDisabledTime(!disabledTime);
    };

    useEffect(() => {
        const timeDifference = Math.abs(new Date(props.value.until).getTime() - new Date(props.value.from).getTime());

        if(timeDifference <= 86399999 && props.isModalView) setDisabledTime(!disabledTime);
    }, []); //eslint-disable-line

    return (
        <>
            <div>
                <div className={styles.inputContainer}>
                    {props.isModalView && <b>{ translate("calendar.from") }</b>}
                    <div className={styles.inputBlock}>
                        <DateInput isReadonly={props.isReadonly} id={"from"} locale={props.locale} date={props.value.from} onChange={onChangeFrom} />
                        <span className={styles.inputTime}>
                            <TextInput value={value?.from?.toLocaleTimeString().substring(0, 5)} onChange={onTimeFrom} isEnabled={!disabledTime} type="time" icon="Clock" />
                        </span>
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    {props.isModalView && <b>{ translate("calendar.to") }</b>}
                    <div className={styles.inputBlock}>
                        <DateInput id={"to"} isReadonly={props.isReadonly} locale={props.locale} date={props.value.until} onChange={props.isModalView ? onChangeUntilModal : onChangeUntil}/>
                        <span className={styles.inputTime}>
                            <TextInput value={value?.until?.toLocaleTimeString().substring(0, 5)} onChange={onTimeUntil} isEnabled={!disabledTime} type="time" icon="Clock" />
                        </span>
                    </div>
                </div>
            </div>

            { !props.isReadonly &&
                <label>
                    <input type="Checkbox" checked={!disabledTime} onChange={onToggleTime} className={styles.checkbox} />
                    { translate("calendar.setTime")}
                </label>
            }

            {props.isModalView &&
            <div className="flex mt-2 justify-end">
                <Button onClick={onClose}>
                    { translate("actions.cancel") }
                </Button>
                <Button _appearance="primary" disabled={!(value.until && value.from && value.until > value.from)} onClick={onSubmit}>
                    { translate("actions.select") }
                </Button>
            </div>
            }
        </>
    );
};

Calendar.propTypes = {
    value:       PropTypes.object,
    onCancel:    PropTypes.func,
    onChange:    PropTypes.func,
    isModalView: PropTypes.bool,
    isReadonly:  PropTypes.bool,
    locale:      PropTypes.string
};

export { Calendar };
