import PropTypes from "prop-types";
import { numberFormatter } from "../../../helper/numberFormatter";
import styles from "./statisticBadge.module.scss";

const StatisticBadge = ({ ...props }) => {
    const isPositiveIncrease = props.difference > 0;

    return (
        <div className={isPositiveIncrease ? styles.badgeContainerIncr : styles.badgeContainerDecr}>
            {isPositiveIncrease ? "+" : "-"}
            <small>{numberFormatter(Math.abs(props.difference), 9999999)}%</small>
        </div>
    );
};


StatisticBadge.propTypes = {
    difference: PropTypes.number
};

export { StatisticBadge };
