import { useTranslation } from "@circle/gestalt-app";

import styles from "./adminHeader.module.scss";

const AdminHeader = () => {
    const { translate } = useTranslation();

    return (
        <div>
            <h4>{translate("menu.settings.tooltip")}{" "}<span className={styles.subTitle}>{translate("overview.all.system")}</span></h4>
        </div>
    );
};

export { AdminHeader };
