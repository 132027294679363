import { AdministrationContent } from "./administrationContent/AdministrationContent";
import { PlantsSettingsList } from "./plantsList/PlantsSettingsList";

import styles from "./settings.module.scss";

const SettingsView = () => {
    return (
        <div className={styles.container}>
            <PlantsSettingsList />
            <AdministrationContent />
        </div>
    );
};

export { SettingsView };
