import { resolveClassNames } from "palstek";
import PropTypes from "prop-types";
import { useTranslation, useBranch, useTrigger } from "@circle/gestalt-app";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import { BarGraph } from "../../generic/barGraph";
import { HeadersList } from "./headersList/HeadersList";
import { PerformanceList } from "./performanceList";
import { InfoBox } from "../../generic/infoBox/InfoBox";
import { BusyIndicator } from "../../BusyIndicator";

import styles from "./performanceContent.module.scss";

const PerformanceContent = ({ ...props }) => { // eslint-disable-line complexity
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();

    const { content, options, isLoading, durationContent, plantElements } = useBranch({
        durationContent: ["durationContent"],
        content:         ["performanceContent"],
        options:         ["queryOptions"],
        isLoading:       ["loadingViews", "performanceContent"],
        plantElements:   ["referenceElements"]
    });

    const portionProduced = content.produced ? Math.round((content.produced.absolute.current / content.produced.absolute.target) * 100) : null;

    const graphData = {
        producedParts: portionProduced,
        target:        portionProduced !== null ? 100 - portionProduced : null
    };

    useEffect(() => {
        trigger("fetchPerformanceContent", { plantId: props.plantId });

        const interval = setInterval(() => trigger("fetchPerformanceContent", { plantId: props.plantId, polling: true }), 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        trigger("fetchPerformanceContent", { plantId: props.plantId });
    }, [options.calendar]);

    const isContentAvailable = content.partsExist || false;

    return (
        <>
            { isLoading &&
                <div className={styles.item}>
                    <InfoBox
                        isHeader={true}
                        difference={content.produced?.absolute?.target > 0 ? content.performance?.tendency : null}
                        title={translate("overview.performance")}
                        className={"isMediumWidth"}
                        showChevron={isContentAvailable}
                    >
                        <BusyIndicator />
                    </InfoBox>
                </div>
            }
            { (isContentAvailable && !isLoading) &&
                <Link className={styles.item} to={isContentAvailable ? `/performance/${props.plantId}` : null}>
                    <InfoBox
                        isHeader={true}
                        difference={content.produced?.absolute?.target > 0 ? content.performance?.tendency : null}
                        title={translate("overview.performance")}
                        className={"isMediumWidth"}
                        showChevron={isContentAvailable}
                    >
                        <div className={styles.header}>
                            <div>
                                <div className={styles.statisticContainer}>
                                    {(content.produced?.absolute?.target > 0 && durationContent.runtime?.duration > 0) ?
                                        <>
                                            <span className={resolveClassNames(styles.statisticTitle)}>{content.performance?.currentlyModified}</span>
                                            <span className={resolveClassNames(styles.statisticTitleDescription)}>%</span>
                                        </> :
                                        <span className={resolveClassNames(styles.statisticTitle)}>{"-"}</span>}
                                </div>
                                <small>{translate("overview.performance")}</small>
                            </div>
                        </div>

                        <div className={styles.contentContainer}>
                            <div className={styles.performanceContainer}>
                                <div className={styles.graphContainer}>
                                    <div className={styles.productionSection}>
                                        <BarGraph data={graphData} isSmall={false}/>
                                        <div className={styles.productionTiles}>
                                            <HeadersList data={{ ...content, runtime: durationContent.runtime?.duration }} translate={translate} language={currentLanguage}/>
                                            <div className={plantElements.length > 0 ? styles.referenceList : styles.none}>
                                                <b>{translate("detail.reference.performance")}</b>
                                                <ul>
                                                    {plantElements.map(x =>
                                                        <li key={x.id}>{translate(x.name)}</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.verticalLine} />

                            <div className={styles.productionContainer}>
                                <div className={resolveClassNames(styles.productionHeaders, content.numberOfProductTypes === 0 && styles.none)}>
                                    <h5 className={styles.topHeader}>Top <b>{content.numberOfProductTypes <= 5 ? content.numberOfProductTypes : 5}</b></h5>
                                    <span>{translate("calendar.from")}</span>
                                    <div>
                                        <h5 className={styles.productionHeader}>{content.numberOfProductTypes}</h5>
                                        <small className={styles.productionSubheader}>{translate("overview.product.types")}</small>
                                    </div>
                                </div>
                                <PerformanceList translate={translate} data={content.top5 ?? []}/>
                            </div>
                        </div>
                    </InfoBox>
                </Link>
            }
            { (!isContentAvailable && !isLoading) &&
                <div className={styles.item}>
                    <InfoBox
                        isHeader={true}
                        difference={content.produced?.absolute?.target > 0 ? content.performance?.tendency : null}
                        title={translate("overview.performance")}
                        className={"isMediumWidth"}
                        showChevron={isContentAvailable}
                    >
                        <div className={styles.noContent}>
                            <span>{translate("overview.no.content")}</span>
                        </div>
                    </InfoBox>
                </div>
            }
        </>
    );
};

PerformanceContent.propTypes = {
    plantId: PropTypes.string
};

export { PerformanceContent };
