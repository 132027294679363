import PropTypes from "prop-types";
import styles from "./statisticBadge.module.scss";

const StatisticBadge = ({ ...props }) => {
    const isPositiveIncrease = props.difference > 0;

    return (
        <div className={isPositiveIncrease ? styles.badgeContainerIncr : styles.badgeContainerDecr}>
            {isPositiveIncrease ? "+" : "-"}
            <small>{Math.abs(props.difference)}%</small>
        </div>
    );
};


StatisticBadge.propTypes = {
    difference: PropTypes.number
};

export { StatisticBadge };
