import { useEffect, useRef, useState } from "react";
import { Button, Icon, SwitchButton } from "@circle/kip-components";
import { useBranch, useTranslation } from "@circle/gestalt-app";

import { InfoBox } from "../../generic/infoBox/InfoBox";
import { PlantAdministration } from "./plantAdministration/PlantAdministration";
import { ClusterAdministration } from "./clusterAdministration/ClusterAdministration";

import styles from "./administrationContent.module.scss";

const AdministrationContent = () => {
    const { translate }       = useTranslation();
    const [filter, setFilter] = useState("system");
    const [modal, setModal]   = useState(false);
    const ref = useRef();

    const { plant } = useBranch({
        plant: ["selectedPlant"]
    });

    const onSelect = e => {
        setFilter(e.target.value);
    };

    const onCancel = () => {
        setModal(false);
    };

    useEffect(() => {
        setModal(false);
    }, []);

    return (
        <div className={styles.container}>
            <InfoBox className="noScroll">
                {
                    !plant &&
                    <div className={styles.noContent}>
                        <h4>{translate("settings.no.content")}</h4>
                    </div>
                }
                {
                    plant &&
                    <>
                        <div className={styles.headerContainer}>
                            <div className={styles.switchButtonContainer}>
                                <SwitchButton
                                    onChange={onSelect}
                                    options={[
                                        {
                                            value:   "system",
                                            checked: filter === "system",
                                            label:   translate("plant.elem.header")
                                        },
                                        {
                                            value:   "cluster",
                                            checked: filter === "cluster",
                                            label:   translate("cluster.header")
                                        }]}
                                />
                            </div>
                            {
                                filter === "cluster" &&
                                <div className={styles.addButton}>
                                    <Button _variant="icon" onClick={() => setModal(true)}>
                                        <Icon _icon="Add" />
                                    </Button>
                                </div>
                            }
                        </div>
                        {
                            filter === "system" &&
                            <PlantAdministration
                                modalRef={ref}
                                onCancel={onCancel}
                            />
                        }
                        {
                            filter === "cluster" &&
                            <ClusterAdministration
                                isCreate={modal}
                                onCancel={onCancel}
                                modalRef={ref}
                            />
                        }
                    </>
                }
            </InfoBox>
            <div id="settings-modal" ref={ref}/>
        </div>
    );
};

export { AdministrationContent };
