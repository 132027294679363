import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./statisticsList.module.scss";

const StatisticsList = ({ ...props }) => {
    const renderSubtitles = (data, isChildren) => {
        return (
            <div className={styles.subtitleContainer}>
                <div className={isChildren ? styles.subtitleChildren : styles.subtitle}>
                    <span className={styles.mr1}><b>{data.duration}</b> {props.translate("overview.minutes.short")}</span>
                    <span className={styles.proportion}><b>{data.portion ?? 0}</b> %</span>
                </div>
            </div>
        );
    };

    const renderList = (items, isChildren) => (
        <ul className={isChildren ? resolveClassNames(styles.list, styles.mt1) : styles.list}>
            {Object.keys(items).map((key, index) => {
                const item = items[key];

                return (
                    <li key={index} className={styles.listItem}>
                        <div className={resolveClassNames(styles.circle, styles[key])} />
                        <span className={isChildren ? styles.isChildrenTitle : styles.title}>{props.translate(key)}</span>
                        {renderSubtitles(item, isChildren)}
                        {item.states && renderList(item.states, true)}
                    </li>
                );
            })}
        </ul>
    );

    return <div>{renderList(props.data)}</div>;
};

StatisticsList.propTypes = {
    data:      PropTypes.object,
    translate: PropTypes.func
};

export { StatisticsList };
