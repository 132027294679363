import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { SwitchButton } from "@circle/kip-components";

import styles from "./favoriteViewFilter.module.scss";

const FavoriteViewFilter = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { options }   = useBranch({
        options: ["queryOptions"]
    });

    const onFilterChange = val => {
        const value = {
            orderKey: val.target.value.replace("Desc", "").replace("Asc", ""),
            order:    val.target.value.toLowerCase().includes("asc") ? "asc" : "desc"
        };

        trigger("applyOption", "orderKey", value.orderKey);
        trigger("applyOption", "order", value.order);
    };

    return (
        <SwitchButton
            className={styles.filter}
            onChange={onFilterChange}
            options={[
                {
                    value:   "nameAsc",
                    checked: options.orderKey === "name" && options.order === "asc",
                    label:   translate("filter.sort.name.ascending")
                },
                {
                    value:   "nameDesc",
                    checked: options.orderKey === "name" && options.order === "desc",
                    label:   translate("filter.sort.name.ascending")
                }
            ]}
        />
    );
};

export { FavoriteViewFilter };
