import { Routes, Route, Navigate } from "react-router-dom";
import { ContentHeader } from "./ContentHeader";

import { OverviewRouter } from "./overview/OverviewRouter";
import { RuntimeRouter } from "./runtimeOverview/runtimeRouter";
import { PerformanceRouter } from "./performanceOverview/performanceRouter";
import { EventsRouter } from "./eventsOverview/eventsRouter";
import { FavoriteRouter } from "./favoriteView/favoriteRouter";
import { SettingsRouter } from "./settings/settingsRouter";

const Content = () => {
    return (
        <>
            <ContentHeader />
            <Routes>
                <Route path="/overview/*" element={<OverviewRouter />}/>
                <Route path="/runtime/*" element={<RuntimeRouter/>} />
                <Route path="/performance/*" element={<PerformanceRouter />} />
                <Route path="/events/*" element={<EventsRouter />} />
                <Route path="/favorite/*" element={<FavoriteRouter />} />
                <Route path="/admin/*" element={<SettingsRouter />}/>
                <Route path="*" element={<Navigate to="/overview" replace/>}/>
            </Routes>
        </>
    );
};

export { Content };
