import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { Icon } from "@circle/kip-components";

import { HorizontalBarGraph } from "../../generic/hotizontalBarGraph";
import { millisecondsToSeconds } from "../../../helper/date";
import { numberFormatter } from "../../../helper/numberFormatter";

import styles from "./performanceClusterTable.module.scss";


const PerformanceClusterRow = ({ ...props }) => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const { plantId }   = useParams();

    const isOverall = props.name === "element.overall";

    return (
        <Link to={`/performance/${plantId}/${props.clusterId}/${props.plantElementId}/${window.location.search}`} className={styles.titleLink}>
            <div
                className={resolveClassNames(
                    styles.row,
                    isOverall && styles.overall
                )}
            >
                <div className={resolveClassNames(styles.column, styles.tableTitleContainer)}>
                    <div className={styles.textEnd}>
                        <div>
                            <b>
                                <span title={translate(props.name)} className={styles.clampedTitle}>{translate(props.name)}</span>
                            </b>
                            {
                                props.elements.length > 0 &&
                                <span className={styles.elementsTitle}>{props.elements.length} {translate("detail.runtime.elements")}</span>
                            }
                        </div>
                    </div>
                    <div>
                        {
                            props.elements.length > 0 &&
                            <Icon onClick={props.onEllapse} _icon="ChevronDown" />
                        }
                        {
                            props.elements.length === 0 &&
                            <div className={styles.placeholder} />
                        }
                    </div>
                </div>
                <div className={resolveClassNames(styles.column)} data-columncount={1}>
                    {
                        (props.produced && !props.name) &&
                        <HorizontalBarGraph
                            data={{
                                producedParts: props.produced.current,
                                target:        props.produced.target,
                                performance:   props.performance
                            }}
                            layer={1}
                        />
                    }
                    {
                        (props.produced && props.name) &&
                        <HorizontalBarGraph
                            data={{
                                producedParts: props.produced.current,
                                target:        props.produced.target,
                                performance:   props.performance
                            }}
                            layer={2}
                        />
                    }
                </div>
                <div className={styles.column} data-columncount={1}>
                    {
                        props.produced &&
                        <small>
                            <b>{numberFormatter(props.produced.current, 99999999)}</b>
                            {
                                props.produced.target > 0 &&
                                <>
                                    <span>/</span>
                                    <small className={styles.subTitleTable}>{numberFormatter(props.produced.target, 99999999)}</small>
                                </>
                            }
                        </small>
                    }
                </div>
                <div className={styles.column} data-columncount={1}>
                    {
                        props.avgPartsPerMinute &&
                        <span>
                            <b>{props.avgPartsPerMinute?.toString()?.replace(".", ",")}</b>
                        </span>
                    }
                </div>
                <div className={styles.column} data-columncount={1}>
                    {
                        props.avgCycleTime &&
                        <span>
                            <b>{millisecondsToSeconds(props.avgCycleTime)?.replace(".", ",")}</b>
                        </span>
                    }
                </div>
            </div>
        </Link>
    );
};

PerformanceClusterRow.propTypes = {
    name:              PropTypes.string,
    avgCycleTime:      PropTypes.number,
    avgPartsPerMinute: PropTypes.number,
    performance:       PropTypes.number,
    plantId:           PropTypes.string,
    clusterId:         PropTypes.string,
    plantElementId:    PropTypes.string,
    onClusterClick:    PropTypes.func,
    elements:          PropTypes.arrayOf(PropTypes.object),
    ellapsed:          PropTypes.bool,
    onEllapse:         PropTypes.func,
    sortedOut:         PropTypes.shape({
        absolute: PropTypes.number
    }),
    produced: PropTypes.shape({
        current: PropTypes.number,
        target:  PropTypes.number
    })
};

export { PerformanceClusterRow };
