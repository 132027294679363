import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useParams } from "react-router-dom";

import { usePrevious } from "../../../../hooks/previous";
import { Icon, Modal } from "@circle/kip-components";
import { EventForm } from "../../../EventForm";
import { InfiniteList } from "../../../generic/InfiniteList";

import styles from "./chronologyTable.module.scss";

const ChronologyTable = () => { // eslint-disable-line complexity
    const { translate }     = useTranslation();
    const { trigger }       = useTrigger();
    const params            = useParams();
    const [modal, setModal] = useState(false);
    const [event, setEvent] = useState({});

    const { content, metadata, options, events, sorting, selectedPlant } = useBranch({
        content:       ["eventsContent", "list"],
        metadata:      ["metadata", "eventsContent"],
        options:       ["options"],
        events:        ["events"],
        sorting:       ["sortings", "events"],
        selectedPlant: ["selectedPlant"]
    });

    const optionsPrev = usePrevious(options);

    const onClose = () => {
        setModal(!modal);
        setEvent({});
    };

    const selectEvent = ev => {
        setEvent(ev);
        setModal(!modal);
    };

    const formattedTime = timestamp => new Date(timestamp).toLocaleString("de-EU", {
        hour:   "numeric",
        minute: "numeric",
        day:    "2-digit",
        month:  "2-digit",
        year:   "2-digit"
    });

    const onFetch = () => {
        trigger("fetchEventsContentList", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: false });
    };

    const onSort = val => {
        const inter = sorting.isOrderAsc ? "desc" : "asc";
        const order = val !== sorting.property ? "desc" : inter;

        trigger("fetchEventsContentList", { plantId: params.plantId, orderKey: val, order, reset: true });
        trigger("setSorting", "events", val);
    };

    useEffect(() => {
        trigger("setSorting", "events", "startDateTime");
        trigger("fetchEventsContentList", { plantId: params.plantId, orderKey: "startDateTime", order: "desc", reset: true });
    }, []);

    useEffect(() => {
        if(!events || !options || !optionsPrev) return;

        trigger("fetchEventsContentList", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options, events]);

    return (
        <div className={styles.dataTableContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.headerItem} onClick={() => onSort("name")}>
                    <span>{ (translate("element.name.header")).toUpperCase() }</span>
                    {
                        sorting.property === "name" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("category")}>
                    <span>{ (translate("events.category")).toUpperCase() }</span>
                    {
                        sorting.property === "category" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("startDateTime")}>
                    <span>{ (translate("events.start.time")).toUpperCase() }</span>
                    {
                        sorting.property === "startDateTime" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("duration")}>
                    <span>{ (translate("overview.filter.duration")).toUpperCase() }</span>
                    {
                        sorting.property === "duration" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("endDateTime")}>
                    <span>{ (translate("events.end.time")).toUpperCase() }</span>
                    {
                        sorting.property === "endDateTime" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
            </div>
            {
                (content ?? []).length === 0 &&
                <div>
                    <span>{ translate("datatable.empty") }</span>
                </div>
            }
            <InfiniteList
                className={styles.infinitelist}
                metadata={metadata}
                onFetch={onFetch}
                element="div"
                placeholder={<span />}
                loader={() => <span>Loading...</span>}
            >
                {
                    (content ?? []).map((x, idx) => (
                        <div className={styles.row} key={idx} onClick={() => selectEvent(x)}>
                            <div className={styles.item}>
                                <span className={styles.clamped}><b>{ translate(x.name) }</b></span>
                            </div>
                            <div className={styles.item}>
                                <span className={styles.clamped}><b>{ translate(x.category) }</b></span>
                            </div>
                            <div className={styles.item}>
                                <span>{formattedTime(x.startDateTime)}</span>
                            </div>
                            <div className={styles.item}>
                                <span>{x.duration} {translate("overview.minutes")}</span>
                            </div>
                            <div className={styles.item}>
                                <span>{formattedTime(x.endDateTime)}</span>
                            </div>
                        </div>
                    ))
                }
            </InfiniteList>
            <Modal
                title={translate("title.record.event")}
                isOpen={modal}
                isCloseable={false}
                onClose={onClose}
                className="event-modal"
            >
                <div id="event-form" className="flex-column">
                    <EventForm
                        event={event}
                        isEditable={false}
                        onClose={onClose}
                        selectedPlant={selectedPlant}
                        onSubmit={onClose}
                        plantId={selectedPlant?.id ?? params.plantId}
                        {...event}
                    />
                </div>
            </Modal>
        </div>
    );
};

export { ChronologyTable };
