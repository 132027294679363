import { useEffect, useState } from "react";
import { Button, Icon, TextInput, Modal, ComboBox, OverflowContainer } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import { Checkbox } from "../../../generic/Checkbox";
import { PerformanceModalToggle } from "./performanceModal";
import { AdminModal } from "../../adminModal/AdminModal";
import { RadioButton } from "../../../generic/radioButton/RadioButton";

import styles from "./plantAdministration.module.scss";

const PlantAdministration = ({ ...props }) => { // eslint-disable-line complexity, max-statements
    const { trigger } = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const [selected, setSelected] = useState([]);
    const [modal, setModal] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [clicked, setClicked] = useState(null);
    const { plantElementsOfPlant, sorting, language, search, plantElementsList } = useBranch({
        plantElementsOfPlant: ["plantElementsOfPlant"],
        plantElementsList:    ["plantElementsList"],
        sorting:              ["sortings", "plantElements"],
        language:             ["language"],
        search:               ["search"]
    });
    const [editElement, setEditElement] = useState({});

    const formattedTarget = editElement.target !== "" ? new Intl.NumberFormat(language, { style: "decimal" }).format(editElement.target) : null;

    const onSort = property => {
        trigger("setSorting", "plantElements", property);
    };

    const onSelectAll = () => {
        const allSelected = plantElementsOfPlant.every(element => selected.includes(element.id));

        if(allSelected) return setSelected([]);

        return setSelected(plantElementsOfPlant.map(element => element.id));
    };

    const onRowClick = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if(e.target.tagName.toUpperCase() === "BUTTON") return;

        setSelected(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id));
    };

    const onSelect = id => {
        setSelected(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id));
    };

    const onInputChange = (key, value) => {
        const values = {
            ...editElement,
            [key]: value
        };

        if(Object.keys(editElement).length === 0) return setEditElement(prev => ({ ...prev, [key]: value }));

        return setEditElement(values);
    };

    const onPlantSettings = (e, id) => {
        e.preventDefault();
        const element = plantElementsList?.find(x => x.id === id);

        trigger("selectPlantElement", {
            ...element,
            target: ["0", "0.0"].indexOf(element?.target) >= 0 ? "" : element.target
        });
        setEditElement({
            ...element,
            target: ["0", "0.0"].indexOf(element?.target) >= 0 ? "" : element.target
        });
        setModal(prevModal => !prevModal);
    };

    const plantElementsOptions = plantElementsList.filter(x => x.id !== editElement.id)
        .sort((a, b) => translate(a.name).localeCompare(translate(b.name)))
        .map((x, key) => ({
            value: translate(x.name),
            label: <span key={key}>
                <span>
                    <b>{translate(x.name) || ""}</b>
                </span>
            </span>
        })) || [];

    const onStatusChange = value => {
        const valueToSave = plantElementsList.find(x => translate(x.name) === value[0]);

        onInputChange("reference", valueToSave?.id || null);
    };

    const handleInputChange = e => {
        onInputChange("target", e.target.value);
    };

    const onClear = () => {
        setEditElement({
            ...editElement,
            target: ""
        });
    };

    const onCancel = () => {
        setModal(!modal);
        setEditElement({});
    };

    const onSave = () => {
        trigger("editPlantElement", {
            ...editElement,
            target: editElement.target.length === 0 ? "0" : editElement.target
        });

        setModal(!modal);
        setEditElement({});
    };

    const onElementsChange = values => {
        setSelected([]);
        trigger("editPlantElements", values.map(x => ({
            ...x,
            target: (x.target.length === 0 || !x.target) ? "0" : x.target
        })));
    };

    const onDelete = () => {
        trigger("deletePlantEle", clicked);
        setConfirm(false);
        setClicked(null);
    };

    const onTriggerSearch = e => {
        trigger("searchElements", e.target.value);
    };

    useEffect(() => {
        trigger("searchElements", "");
    }, []);

    useEffect(() => {
        trigger("setSorting", "plantElements", "name");
    }, []);

    const elementsForChanging = plantElementsOfPlant.filter(object => selected.includes(object.id));

    const referenceValue = translate(plantElementsOfPlant.find(x => x.id === editElement.reference)?.name);

    return (
        <div>
            <TextInput
                onChange={onTriggerSearch}
                className={styles.search}
                placeholder={translate("search.element")}
                icon="Search"
                value={search}
            />
            <div className={styles.mainContainer}>
                <table className={styles.messageTable}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "2%" }} className={styles.textLeft}>{plantElementsOfPlant?.length > 0 && <Checkbox checked={plantElementsOfPlant.length === selected.length} onChange={onSelectAll} />}</th>
                            <th scope="col" style={{ width: "20%" }} className={resolveClassNames(styles.clickable, styles.textLeft)}>
                                <small onClick={() => onSort("name")} className={styles.clickable}>{translate("element.name.header")}</small>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "name" &&
                                    <Icon onClick={() => onSort("name")} className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </th>
                            <th scope="col" style={{ width: "15%" }} className={styles.clickable}>
                                <small onClick={() => onSort("status")} className={styles.clickable}>{translate("status.header")}</small>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "status" &&
                                    <Icon onClick={() => onSort("status")} className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </th>
                            <th scope="col" style={{ width: "15%" }} className={styles.clickable}>
                                <small onClick={() => onSort("type")} className={styles.clickable}>{translate("dashboard.widgets.produced.type")}</small>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "type" &&
                                    <Icon onClick={() => onSort("type")} className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </th>
                            <th scope="col" style={{ width: "15%" }} className={resolveClassNames(styles.textCenter, styles.clickable)}>
                                <small onClick={() => onSort("isReferenceObject")} className={styles.clickable}>{translate("reference.header")}</small>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "isReferenceObject" &&
                                    <Icon onClick={() => onSort("isReferenceObject")} className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </th>
                            <th scope="col" style={{ width: "15%" }} className={resolveClassNames(styles.textCenter, styles.clickable)}>
                                <small onClick={() => onSort("target")} className={styles.clickable}>{translate("admin.performance.target")}</small>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "target" &&
                                    <Icon onClick={() => onSort("target")} className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </th>
                            <th scope="col" style={{ flex: 0.8 }} className={styles.textCenter} />
                            <th scope="col">
                                <div className={styles.groupsHeader}>
                                    <PerformanceModalToggle selected={elementsForChanging} onChange={onElementsChange} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <OverflowContainer className={styles.scroll}>
                        <tbody>
                            {
                                plantElementsOfPlant?.length === 0 &&
                                <tr className={resolveClassNames(styles.noContent)}>
                                    <td className={styles.noContent}>{translate("overview.no.content")}</td>
                                </tr>
                            }
                            {
                                plantElementsOfPlant.map((x, key) => (
                                    <tr key={key} className={resolveClassNames(styles.rowContainer, selected.includes(x.id) && styles.isActive)} onClick={e => onRowClick(e, x.id)}>
                                        <td scope="row" style={{ width: "2%" }}>
                                            <div className={styles.textRight} onClick={e => e.stopPropagation()}>
                                                <Checkbox className={styles.maxZIndex} checked={selected.includes(x.id)} onChange={() => onSelect(x.id)} />
                                            </div>
                                        </td>
                                        <td scope="row" style={{ width: "20%" }}>
                                            <span>{translate(x.name)}</span>
                                        </td>
                                        <td scope="row" style={{ width: "15%" }}>
                                            <span>{translate(x.status)}</span>
                                        </td>
                                        <td scope="row" style={{ width: "15%" }}>
                                            <span>{x.type}</span>
                                        </td>
                                        <td scope="row" style={{ width: "15%" }}>
                                            <span className={styles.textCenter}>{x.isReferenceObject ? <Icon _icon="Check" /> : "–"}</span>
                                        </td>
                                        <td scope="row" style={{ width: "15%" }}>
                                            <span className={styles.textCenter}>{["0", "0.0"].indexOf(x?.target) >= 0 ? "" : new Intl.NumberFormat(currentLanguage).format(x?.target)}</span>
                                        </td>
                                        <td scope="row" style={{ width: "15%" }}>
                                            <div className={styles.textRight}>
                                                {
                                                    x.status === "inactive" &&
                                                    <Button
                                                        className={styles.button}
                                                        _variant="icon"
                                                        onClick={() => {
                                                            setConfirm(true);
                                                            setClicked(x.id);
                                                        }}
                                                    >
                                                        <Icon _icon="Trashcan" />
                                                    </Button>
                                                }
                                                <Button className={styles.button} _variant="icon" onClick={e => onPlantSettings(e, x.id)}>
                                                    <Icon _icon="Edit" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </OverflowContainer>
                </table>
                {
                    props.modalRef.current &&
                    createPortal(
                        <AdminModal
                            title={`${translate(editElement?.name)} ${translate("plant.edit")}`}
                            isOpen={modal}
                        >
                            <div className={styles.modalContainer}>
                                <div className={styles.contentContainer}>
                                    <span className={styles.inputLabel}>{translate("target.elements.description")}</span>
                                    <div className={styles.inputContainer}>
                                        <div className={styles.inputField}>
                                            <TextInput className={styles.input} value={!isNaN(parseFloat(formattedTarget)) ? formattedTarget : editElement.target} onChange={handleInputChange} />
                                            <div className={styles.inputDelete} onClick={onClear}>
                                                <Icon className={styles.inputDeleteIcon} _icon="Close" />
                                            </div>
                                        </div>
                                        <small className={styles.inputDescription}>{translate("input.description.elements.min")}</small>
                                    </div>

                                    <span className={styles.inputLabel}>{translate("reference.header")}*</span>
                                    <div className={styles.radioInputContainer}>
                                        <RadioButton value={"true"} name="true" disabled={editElement.isRefObjDisabled} checked={editElement.isReferenceObject} onChange={e => onInputChange("isReferenceObject", JSON.parse(e.target.value))}>
                                            {translate("action.yes")}
                                        </RadioButton>
                                        <RadioButton value={"false"} name="false" disabled={editElement.isRefObjDisabled} checked={!editElement.isReferenceObject} onChange={e => onInputChange("isReferenceObject", JSON.parse(e.target.value))}>
                                            {translate("action.no")}
                                        </RadioButton>
                                    </div>

                                    <div className={styles.divider} />

                                    <span className={styles.inputLabel}>{translate("reference.element.status")}</span>
                                    <div className={styles.elementSelectTextContainer}>
                                        <div className={styles.descriptionText}>
                                            <small>{translate("reference.element.first.part.tip")} </small>
                                            <b>
                                                <small>{translate("reference.element.bold.part")}</small>
                                            </b>
                                            <small> {translate("reference.element.second.part.tip")}</small>
                                        </div>
                                        <small className={styles.descriptionText}>{translate("reference.element.third.part.tip")}</small>
                                    </div>
                                    <ComboBox
                                        id="status"
                                        onChange={onStatusChange}
                                        isEnabled={true}
                                        isEmptyValueAllowed={true}
                                        isCustomInputEnabled={true}
                                        enableOptionsDelete={true}
                                        disableArrowButton
                                        options={plantElementsOptions}
                                        value={referenceValue}
                                        triggerOnChangeOnOptionsChange={true}
                                        className={resolveClassNames("full-width")}
                                        isMulti={false}
                                    />
                                </div>
                                <div className={styles.modalFooter}>
                                    <Button className={styles.footerButton} onClick={onCancel}>
                                        {translate("actions.cancel")}
                                    </Button>
                                    <Button className={styles.footerButton} _appearance="primary" onClick={onSave}>
                                        {translate("actions.save")}
                                    </Button>
                                </div>
                            </div>
                        </AdminModal>,
                        props.modalRef.current
                    )
                }
                <Modal
                    isOpen={confirm}
                    isCloseable={false}
                    title={translate("modal.delete.danger")}
                >
                    <div className={styles.modal}>
                        <span>{translate("modal.delete.really")}</span>
                        <div>
                            <Button _appearance="primary" onClick={() => setConfirm(!confirm)}>{translate("actions.no")}</Button>
                            <Button onClick={onDelete}>{translate("actions.yes")}</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

PlantAdministration.propTypes = {
    onCancel: PropTypes.func,
    modalRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
};

export { PlantAdministration };
