import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { PlantOverviewHeader } from "../generic/plantOverviewHeader/PlantOverviewHeader";
import { EventButton } from "../EventButton";
import { PerformanceHeader } from "./performanceHeader/PerformanceHeader";
import { PerformanceClusterTable } from "./performanceClusterTable/PerformanceClusterTable";
import { CombinedGraphWidget } from "../widgets/combinedGraphWidget/CombinedGraphWidget";
import { TableWidget } from "../widgets/tableWidget/TableWidget";
import { BusyIndicator } from "../BusyIndicator";

import styles from "./performanceOverview.module.scss";

const PerformanceOverview = () => {
    const params                         = useParams();
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();

    const { licensedPlants, plant, options, content, isLoading, plantElements } = useBranch({
        licensedPlants: ["licensedPlants"],
        plant:          ["selectedPlant"],
        options:        ["queryOptions"],
        content:        ["performanceOverview"],
        plantElements:  ["referenceElements"],
        isLoading:      ["loadingViews", "performanceOverview"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   licensedPlants,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    useEffect(() => {
        trigger("applyPlant", params.plantId);
        trigger("resetCluster");
        trigger("resetPlantElement");
    }, []);

    useEffect(() => {
        trigger("fetchPerformanceOverview", { plantId: params.plantId });

        return () => trigger("clearMemory");
    }, []);

    return (
        <>
            {
                init &&
                <div className={styles.container}>
                    <div className={styles.header}>
                        <PlantOverviewHeader
                            plant={plant}
                            options={options}
                            currentLanguage={currentLanguage}
                            translate={translate}
                        />
                        <EventButton />
                    </div>

                    <div className={styles.container}>
                        {
                            isLoading &&
                            <BusyIndicator />
                        }
                        <div className={styles.performanceContainer}>
                            {
                                !isLoading &&
                                <PerformanceHeader data={content ?? {}} references={plantElements} />
                            }

                            <div className={styles.performanceTitle}>
                                <h6 className={styles.headline}>{translate("overview.performance")}</h6>
                                <span>{translate("overview.all.system")}</span>
                            </div>

                            <div className={styles.content}>
                                <div className={styles.clusterContent}>
                                    <PerformanceClusterTable />
                                </div>
                                <div className={styles.performanceContent}>
                                    <CombinedGraphWidget
                                        mainHeaderTitle={translate("detail.history")}
                                        secondaryHeaderTitle={translate("overview.produced.parts")}
                                        data={content.graph ?? []}
                                        isLoading={isLoading}
                                        translate={translate}
                                    />
                                    <TableWidget
                                        mainHeaderTitle={translate("overview.product.types")}
                                        data={content.productTypes ?? []}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export { PerformanceOverview };
