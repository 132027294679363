interface Data {
    getter: (val: string) => string,
    items: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
    property: string,
    ordering: string
}

export const sort = ({ items, property, ordering = "asc", getter }: Data) => {
    const order    = ordering.toLowerCase();
    const newItems = items?.concat([]);


    newItems.sort((a, b) => {
        const valA = getter ? getter(a) : a[property];
        const valB = getter ? getter(b) : b[property];

        if(typeof valA === "number") return order === "asc" ? valA - valB : valB - valA;

        return order === "asc" ? (valA ?? "").toString().localeCompare((valB ?? "").toString()) : (valB ?? "").toString().localeCompare((valA ?? "").toString());
    });

    return newItems;
};
