import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Icon } from "@circle/kip-components";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";

import styles from "./clusterProductsList.module.scss";
import { InfiniteList } from "../../../generic/InfiniteList.jsx";

const ClusterProductsList = () => {
    const { trigger } = useTrigger();
    const params = useParams();

    const { translate, currentLanguage } = useTranslation();

    const { sorting, options, metadata, chronologicalProducts } = useBranch({
        sorting:               ["sortings", "chronologicalProducts"],
        options:               ["queryOptions"],
        metadata:              ["metadata", "chronologicalProducts"],
        chronologicalProducts: ["sortedChronologicalProducts"]
    });

    const onFetch = () => {
        trigger("fetchClusterChronologicalProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: false });
    };

    const onSort = val => {
        const inter = sorting.isOrderAsc ? "desc" : "asc";
        const order = val !== sorting.property ? "desc" : inter;

        trigger("fetchClusterChronologicalProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: val, order, reset: true });
        trigger("setSorting", "chronologicalProducts", val);
    };

    useEffect(() => {
        trigger("fetchClusterChronologicalProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, []);

    useEffect(() => {
        trigger("fetchClusterChronologicalProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options]);

    return (
        <div className={resolveClassNames(styles.dataTableContainer)}>
            <div className={styles.headerContainer}>
                <div className={styles.headerItem} onClick={() => onSort("name")}>
                    <span>{(translate("name.headline")).toUpperCase()}</span>
                    {
                        sorting.property === "name" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("processingTime")}>
                    <span>{(translate("detail.cluster.table.prod.time")).toUpperCase()}</span>
                    {
                        sorting.property === "processingTime" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
                <div className={styles.headerItem} onClick={() => onSort("createdAt")}>
                    <span>{(translate("products.creation.time")).toUpperCase()}</span>
                    {
                        sorting.property === "createdAt" &&
                        <div className={styles.rowHeader}>
                            <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                        </div>
                    }
                </div>
            </div>
            {
                (chronologicalProducts ?? []).length === 0 &&
                <div>
                    <span>{translate("datatable.empty")}</span>
                </div>
            }
            <InfiniteList
                className={styles.infinitelist}
                metadata={metadata}
                onFetch={onFetch}
                element="div"
                placeholder={<span />}
                loader={() => <span>Loading...</span>}
            >
                {
                    (chronologicalProducts ?? []).map((x, idx) => (
                        <div className={styles.rowContainer} key={idx}>
                            <div className={styles.row}>
                                <div className={styles.item}>
                                    <span className={styles.clamped}><b>{translate(x.name)}</b></span>
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.clamped}><b>{x?.processingTime > 0 ? `${x?.processingTime.toLocaleString(currentLanguage)}s` : "-"}</b></span>
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.clamped}>{new Date(x.createdAt).toLocaleString(currentLanguage, { timeZone: "UTC", hour12: false })}</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </InfiniteList>
        </div>
    );
};

export { ClusterProductsList };
