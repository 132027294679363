import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Icon } from "@circle/kip-components";
import { resolveClassNames } from "palstek";

import { PlantOverviewHeader } from "../../generic/plantOverviewHeader/PlantOverviewHeader";
import { HeaderPieGraph } from "../headerPieGraph/HeaderPieGraph";
import { ClusterGraphsContent } from "./clusterGraphsContent";
import { ClusterStatisticsContent } from "./clusterStatisticsContent";
import { EventButton } from "../../EventButton";
import { BusyIndicator } from "../../BusyIndicator";
import { useQueryString } from "../../../hooks/querystring";
import { useQuery } from "../../../hooks/query";
import { getKind, getType } from "../../../helper/cluster";

import styles from "./clusterRuntimeOverview.module.scss";

const ClusterRuntimeOverview = () => { // eslint-disable-line complexity
    const params                         = useParams();
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();

    const { plant, options, content, headerPieGraph, selectedCluster, isFavorite, isLoading, licensedPlants, selectedPlantElement } = useBranch({
        plant:                ["selectedPlant"],
        options:              ["queryOptions"],
        content:              ["clusterRuntimeOverview"],
        headerPieGraph:       ["headerPieGraph"],
        selectedCluster:      ["selectedCluster"],
        isFavorite:           ["isFavorite"],
        isLoading:            ["loadingViews", "clusterRuntimeOverview"],
        licensedPlants:       ["licensedPlants"],
        selectedPlantElement: ["selectedPlantElement"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   licensedPlants,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    const onFavoriteSelect = () => {
        if(selectedPlantElement?.id) {
            const favoriteElement =  {
                clusterId:      null,
                plantId:        params.plantId,
                plantElementId: params.plantElementId
            };

            trigger("editFavoriteElement", favoriteElement);
            return;
        }
        const favoriteElement =  {
            clusterId:      selectedCluster.id,
            plantId:        selectedCluster.plantId,
            plantElementId: null
        };

        trigger("editFavorite", favoriteElement);
        return;
    };

    useEffect(() => {
        trigger("applyPlant", params.plantId);
        trigger("applyCluster", params.plantId, params.clusterId);
        trigger("applyPlantElement", params.plantElementId);
    }, [params.plantId]);

    useEffect(() => {
        trigger("fetchClusterRuntimeOverview", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId });

        return () => trigger("clearMemory");
    }, []);

    const kind = getKind(params);
    const type = getType(kind, selectedCluster, selectedPlantElement);

    return (
        <>
            {
                init &&
                <>
                    <div className={styles.header}>
                        <PlantOverviewHeader
                            plant={plant}
                            options={options}
                            currentLanguage={currentLanguage}
                            translate={translate}
                        />
                        <div className={styles.headerButtons}>
                            {
                                (selectedCluster?.id || selectedPlantElement?.id) &&
                                <Button _variant="icon" onClick={onFavoriteSelect} _appearance={isFavorite ? "primary" : "default"}>
                                    <Icon _icon={isFavorite ? "StarSolid" : "Star"}/>
                                </Button>

                            }
                            <EventButton isNested={true} />
                        </div>
                    </div>
                    {
                        isLoading &&
                        <BusyIndicator />
                    }
                    <div className={resolveClassNames(styles[type], styles.container)}>
                        {
                            !isLoading && type.includes("States") &&
                            <HeaderPieGraph {...headerPieGraph} />
                        }
                        <div className={styles.headline}>
                            <h6 className={styles.headline}>
                                <b>{translate("detail.cluster.overview")}</b>
                            </h6>
                        </div>
                        <div className={styles.contentContainer}>
                            <ClusterGraphsContent
                                type={type}
                                interruptions={content.interruptions ?? []}
                                performance={content.performanceGraph ?? []}
                                isLoading={isLoading}
                            />
                            <ClusterStatisticsContent
                                type={type}
                                runtimeCheck={content.runtime?.runtime?.duration > 0}
                                performance={content.performance ?? {}}
                                performanceGraph={content.speedCourse ?? []}
                                availability={content.availability ?? []}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export { ClusterRuntimeOverview };
