import PropTypes from "prop-types";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useBranch, useTranslation } from "@circle/gestalt-app";

import { InfoBox } from "../../../generic/infoBox/InfoBox";
import { renderCustomizedLabel } from "../../../../helper/customizeLabel.jsx";
import { Tooltip as CustomTooltip } from "../../../generic/Tooltip";
import { BusyIndicator } from "../../../BusyIndicator.jsx";
import { ClusterProductTypes } from "../clusterProductTypes/ClusterProductTypes.jsx";

import styles from "./clusterGraphsContent.module.scss";

const ClusterGraphsContent = ({ type = "cluster", ...props }) => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const { isLoading } = useBranch({
        isLoading: ["loadingViews", "clusterRuntimeOverview"]
    });

    return (
        <div className={styles.mainContainer}>
            <InfoBox className="noScroll">
                {
                    isLoading &&
                    <BusyIndicator />
                }
                <div>
                    {
                        !isLoading && type.includes("States") &&
                            <>
                                {
                                    props.interruptions.length === 0 &&
                                    <div className={styles.noContent}>
                                        <p>{translate("overview.no.content")}</p>
                                    </div>
                                }
                                {
                                    props.interruptions.length >= 1 &&
                                    <>
                                        <p className={styles.header}>{translate("detail.cluster.table.interruption")}</p>
                                        <ResponsiveContainer width="100%" height={200}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={props.interruptions}
                                                syncId="anyId"
                                                margin={{
                                                    top:    20,
                                                    right:  10,
                                                    left:   0,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                                                <XAxis dataKey="date" />
                                                <YAxis axisLine={{ stroke: "transparent" }} domain={[0, 60]}/>
                                                <Tooltip content={<CustomTooltip translate={translate} />}/>
                                                <Bar dataKey="standstill" stackId="b" fill="#A8A8A8" />
                                                <Bar dataKey="error" stackId="b" fill="#EB6060" />
                                                <Bar dataKey="setup" stackId="b" fill="#D5E2BF" />
                                                <Bar dataKey="blocked" stackId="b" fill="#F7E463" />
                                                <Bar dataKey="maintenance" stackId="b" fill="#5EAEFF" />
                                                <Bar dataKey="notReady" stackId="b" fill="#FF9966" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </>
                                }
                            </>
                    }
                    {
                        (!isLoading && type.includes("Counter")) &&
                            <>
                                {
                                    props.performance.length === 0 &&
                                    <div className={styles.noContent}>
                                        <p>{translate("overview.no.content")}</p>
                                    </div>
                                }
                                {
                                    props.performance.length >= 1 &&
                                    <>
                                        <p className={styles.header}>{translate("overview.performance")} | <span className={styles.red}>{translate("overview.sorted.out")}</span></p>
                                        <ResponsiveContainer width="100%" height={200}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                syncId="anyId"
                                                data={props.performance}
                                                margin={{
                                                    top:    20,
                                                    right:  10,
                                                    left:   0,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                                                <XAxis dataKey="date" />
                                                <YAxis axisLine={{ stroke: "transparent" }} />
                                                <Tooltip content={<CustomTooltip translate={translate} />}/>
                                                <Bar dataKey="produced" stackId="a" fill="#7DA7D9">
                                                    <LabelList
                                                        dataKey="produced"
                                                        content={({ value, x, y, width, height }) => {
                                                            if(value === 0)
                                                                return null;

                                                            return (
                                                                <text x={x + width / 2} y={y + height / 2} dy={4} fontSize={10} textAnchor="middle">
                                                                    {value}
                                                                </text>
                                                            );
                                                        }}
                                                    />

                                                    <LabelList dataKey="sortedOut" content={labelProps => renderCustomizedLabel({ ...labelProps, color: "#EB6060", fontWeight: "bold" })} />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </>
                                }
                            </>
                    }
                </div>
                {
                    type.includes("Counter") &&
                    <ClusterProductTypes type={type}/>
                }
            </InfoBox>
        </div>
    );
};

ClusterGraphsContent.propTypes = {
    interruptions: PropTypes.arrayOf(PropTypes.object),
    performance:   PropTypes.arrayOf(PropTypes.object),
    type:          PropTypes.string
};

export { ClusterGraphsContent };
