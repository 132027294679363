import { useTranslation, useTrigger, useBranch } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { resolveClassNames } from "palstek";

import styles from "./overviewItem.module.scss";

import { BusyIndicator } from "../BusyIndicator";

const OverviewItem = ({ ...props }) => { // eslint-disable-line complexity
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { options }   = useBranch({
        options: ["queryOptions"]
    });

    const chartRuntimeData = ["used", "errors", "organizationals"].map(name => ({
        name:    name,
        value:   props.item.states ? props.item.states[name]?.duration : null,
        portion: props.item.states ? props.item.states[name]?.portion : null
    })).concat({ name: "noData", value: 0.00000001, portion: 0.00000001 });


    const portionProduced = props.item.performanceData ? Math.round((props.item.performanceData.absolute.current / props.item.performanceData.absolute.target) * 100) : null;

    const performanceGraph = [
        { name: "producedParts", value: portionProduced },
        { name: "target", value: portionProduced !== null ? 100 - portionProduced : 100 },
        { name: "noData", value: 0.1 }
    ];

    const COLORS = [
        { errors: "#EB6060" },
        { organizationals: "#F3C173" },
        { used: "#7CD992" },
        { producedParts: "#7DA7D9" },
        { target: "#D7D7D7" },
        { noData: "#D7D7D7" }
    ];

    const pieDataWithColors = chartRuntimeData.map(item => ({
        ...item,
        color: COLORS.find(color => color[item.name])?.[item.name]
    })) || [];

    const pipeDataPerformance = performanceGraph.map(item => ({
        ...item,
        color: COLORS.find(color => color[item.name])?.[item.name]
    })) || [];

    useEffect(() => {
        trigger("fetchOverviewItem", { plantId: props.item.id });

        const interval = setInterval(() => trigger("fetchOverviewItem", { plantId: props.item.id }), 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        trigger("fetchOverviewItem", { plantId: props.item.id, reset: true });
    }, [options.calendar]);

    return (
        <Link className={styles.item} to={`/overview/${props.item.id}${window.location.search}`} >
            <div className={styles.itemContent}>
                <div className={styles.identifier}>
                    <img className={styles.logo} src={props.item.image} loading="lazy" alt="Manufacturer Logo" />
                    <div className={styles.nameContainer}>
                        <b className={styles.name}>{props.item.name}</b>
                        <span>{translate(props.item.location)}</span>
                    </div>
                    {
                    !props.item.hasOwnProperty("availability") && // eslint-disable-line no-prototype-builtins
                    <span>
                        <BusyIndicator />
                    </span>
                    }
                </div>
                <div className={styles.metricsContent}>
                    <div className={styles.metricsContainer}>
                        <div className={styles.chartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={4} height={4}>
                                    <Pie
                                    data={chartRuntimeData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={10}
                                    outerRadius={22.5}
                                    dataKey="value"
                                    >
                                        {pieDataWithColors.map((entry, index) => (
                                            <Cell key={index} fill={entry.color} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className={styles.metricsSection}>
                            <div className={styles.inline}>{props.item.runtime > 0 ? <><b>{props.item.techAvailability}</b><span>%</span></> : "–"}</div>
                            <div><small className={styles.itemName}>{translate("overview.item.technicalAvailability")}</small></div>
                        </div>
                        <div className={styles.metricsSection}>
                            <div className={styles.inline}>{props.item.runtime > 0 ? <><b>{props.item.availability}</b><span>%</span></> : "–"}</div>
                            <div><small className={styles.itemName}>{translate("overview.item.availability")}</small></div>
                        </div>
                        <div className={resolveClassNames(styles.metricsSection, styles.fixedSection)}>
                            <div className={styles.inline}><b>{props.item.runtime ? props.item.runtimeModified : 0}</b><span>{translate("overview.minutes.short")}</span></div>
                            <div><small className={styles.itemName}>{translate("overview.item.used")}</small></div>
                        </div>
                    </div>
                    <div className={styles.dividerContainer}>
                        <div className={styles.divider}/>
                    </div>
                    <div className={styles.metricsContainer}>
                        <div className={styles.chartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={4} height={4}>
                                    <Pie
                                    data={performanceGraph}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={10}
                                    outerRadius={22.5}
                                    dataKey="value"
                                    >
                                        {pipeDataPerformance.map((entry, index) => (
                                            <Cell key={index} fill={entry.color} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className={styles.metricsSection}>
                            <div className={styles.inline}>{(props.item.performanceData?.absolute?.target > 0 && props.item.runtime > 0) ?
                                <><b>{props.item.performance}</b><span>%</span></> :
                                "–"}
                            </div>
                            <div><small className={styles.itemName}>{translate("overview.item.performance")}</small></div>
                        </div>
                        <div className={styles.metricsSection}>
                            <div className={styles.inline}>
                                {(props.item.performanceData?.perMinute?.target > 0 && props.item.runtime > 0) ?
                                    <>ø<b>{props.item.performanceData?.perMinute.currentModified}</b></> :
                                "–"}
                                <span>/</span>
                                {props.item.performanceData?.perMinute?.target > 0 ?
                                    <small>{props.item.performanceData?.perMinute.target}</small> :
                                "–"
                                }
                            </div>
                            <div><small className={styles.itemName}>{translate("overview.item.partsPerMinute")}</small></div>
                        </div>
                        <div className={resolveClassNames(styles.metricsSection, styles.fixedSection, styles.negativeMargin)}>
                            <div className={styles.inline}>
                                {props.item.performanceData?.absolute.current > 0 ?
                                    <b>{props.item.performanceData?.absolute.currentModified}</b> :
                                "–"}
                            </div>
                            <div><small className={styles.itemName}>{translate("overview.item.producedParts")}</small></div>
                        </div>
                    </div>
                </div>
                <div className={styles.emptyContent}/>
            </div>
        </Link>
    );
};

OverviewItem.propTypes = {
    item:        PropTypes.object,
    onClick:     PropTypes.func.isRequired,
    onPlusClick: PropTypes.func.isRequired,
    locale:      PropTypes.string
};

export { OverviewItem };
