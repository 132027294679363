import { useTranslation } from "@circle/gestalt-app";
import { SwitchButton } from "@circle/kip-components";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./overviewFilter.module.scss";

const OverviewFilter = ({ ...props }) => {
    const [options, setOptions] = useState([]);
    const { translate } = useTranslation();
    const onSelect = e => {
        const value = {
            orderKey: e.target.value.replace("Desc", "").replace("Asc", ""),
            order:    e.target.value.toLowerCase().includes("asc") ? "asc" : "desc"
        };

        props.onChange(value);
    };

    useEffect(() => {
        const _options = ["techAvailability", "availability", "performance", "runtime", "name"]
            .map(x => [{
                value:   `${x}Desc`,
                checked: props.orderKey === x && props.order === "desc",
                label:   translate(`filter.${x}.descending`)
            }, {
                value:   `${x}Asc`,
                checked: props.orderKey === x && props.order === "asc",
                label:   translate(`filter.${x}.ascending`)
            }]).flat();

        setOptions(_options);
    }, [props.order, props.orderKey]);

    return (
        <SwitchButton
            className={styles.filter}
            onChange={onSelect}
            options={options}
        />
    );
};

OverviewFilter.propTypes = {
    onChange: PropTypes.func,
    orderKey: PropTypes.string,
    order:    PropTypes.string
};

export { OverviewFilter };
