import React, { useState, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";
import { Button, Icon, TextInput } from "@circle/kip-components";
import { useTranslation } from "@circle/gestalt-app";

import { enhanceComponent } from "../../../../../helper/detector";

import styles from "./performanceModal.module.scss";

const PerformanceModal = ({ ...props }, ref) => {
    const { translate }           = useTranslation();
    const [active, setActive]     = useState(false);
    const [value, setValue]       = useState("");
    const [elements, setElements] = useState([]);

    useImperativeHandle(ref, () => ({
        handleClickOutside() {
            if(!active) return null;

            return setActive(!active);
        }
    }));

    const onInputChange = e => {
        setValue(e.target.value);

        const updatedElements = elements.map(element => ({
            ...element,
            target: e.target.value
        }));

        setElements(updatedElements);
    };

    const onSave = () => {
        setActive(!active);
        props.onChange(elements);
    };

    useEffect(() => {
        setElements(props.selected.map(element => ({
            ...element,
            target: "0"
        })));
    }, [props.selected]);

    return (
        <div className={styles.mainContainer}>
            <Button
                className={resolveClassNames(styles.headerButton, props.selected.length >= 1 && styles.visible)}
                _variant="icon"
                onClick={e => {
                    e.stopPropagation();
                    setActive(!active);
                }}
            >
                <Icon _icon="Edit"/>
            </Button>
            {active &&
            <div className={resolveClassNames(styles.modalContainer, active && styles.visible)}>
                <div className={styles.modalHeader}>
                    <small>{translate("target.performance.for.selection")}</small>
                    <div
                        tabIndex={0}
                        role="button"
                        className={styles.closeButton}
                        onClick={() => setActive(!active)}
                    >
                        <Icon className={styles.size} _icon="Close"/>
                    </div>
                </div>
                <div className={styles.textInput}>
                    <TextInput value={value} className={styles.textInput} onChange={onInputChange} />
                </div>
                <div className={styles.footerContainer}>
                    <small className={styles.description}>{translate("modal.description.parts")}</small>
                    <Button className={styles.footerButton} onClick={onSave}>
                        {translate("actions.save")}
                    </Button>
                </div>
            </div>
            }
        </div>
    );
};

PerformanceModal.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
};

const PerformanceModalToggle = enhanceComponent(React.forwardRef(PerformanceModal));

export { PerformanceModalToggle };
