import { useEffect } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

import { FavoriteViewFilter } from "./favoriteViewFilter/FavoriteViewFilter";
import { PlantCard } from "./plantCard";
import { FavoriteCard } from "./favoriteCard/FavoriteCard";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";

import styles from "./favoriteView.module.scss";

const FavoriteView = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { observedClusters, options, licensedPlants } = useBranch({
        observedClusters: ["observedClusters"],
        options:          ["queryOptions"],
        licensedPlants:   ["licensedPlants"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   licensedPlants,
        optional: {
            range:    x => (x ?? "").replace("undefined", "") || options.range || "last7Days",
            order:    () => options.order || "asc",
            orderKey: () => options.orderKey || "name"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime", "order", "orderKey"]
    });

    useEffect(() => {
        trigger("resetSelections");
        trigger("resetCluster");
        trigger("resetPlantElement");
    }, []);

    return (
        <>
            {
                init &&
                <>
                    <div className={styles.header}>
                        <h6>
                            {translate("menu.favorite.tooltip")}
                        </h6>
                    </div>
                    {
                        observedClusters.length === 0 &&
                        <div className={styles.noContent}>
                            <h6>{translate("overview.no.content")}</h6>
                        </div>
                    }
                    {
                        observedClusters.length >= 1 &&
                        <>

                            <div className={styles.filterContainer}>
                                <div>
                                    <span className={styles.sort}>{translate("overview.sort")}</span>
                                </div>
                                <FavoriteViewFilter />
                            </div>
                            <div className={styles.container}>
                                {
                                    observedClusters.map((elem, key) => (
                                        <div key={key} className={styles.plantContainer}>
                                            <PlantCard plant={elem.plant} translate={translate}/>
                                            <div className={styles.clustersContainer}>
                                                {
                                                    elem.clusters?.map((item, i) =>
                                                        <FavoriteCard key={i} item={item} translate={translate}/>
                                                    )}
                                                {
                                                    elem.plantElements?.map((item, i) =>
                                                        <FavoriteCard key={i} item={item} translate={translate}/>
                                                    )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
};

export { FavoriteView };
