import { TextInput } from "@circle/kip-components";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

import { Breadcrumb } from "../../generic/Breadcrumb/Breadcrumb";

import styles from "./favoriteViewHeader.module.scss";
import { useEffect } from "react";

const FavoriteViewHeader = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { search }    = useBranch({
        search: ["search"]
    });

    const onTriggerSearch = e => {
        trigger("search", e.target.value);
    };

    useEffect(() => {
        trigger("search", "");
    }, []);

    return (
        <div className={styles.headerContainer}>
            <Breadcrumb homeLink="/favorite" />
            <div className={styles.searchContainer}>
                <TextInput
                    onChange={onTriggerSearch}
                    className={styles.search}
                    placeholder={translate("search.element.or.cluster")}
                    icon="Search"
                    value={search}
                />
            </div>
        </div>
    );
};

export { FavoriteViewHeader };
