import PropTypes from "prop-types";
import { Icon } from "@circle/kip-components";
import { resolveClassNames } from "palstek";

import { StatisticBadge } from "../statisticBadge/StatisticBadge";

import styles from "./infoBox.module.scss";

const InfoBox = ({ showChevron = true, difference = null, ...props }) => {
    return (
        <div className={resolveClassNames(styles.cardContainer, styles[props.className])}>
            {
                props.isHeader &&
                <div className={styles.header}>
                    <div className={styles.title}>
                        <b>{props.title}</b>
                        {
                            difference !== null &&
                            <StatisticBadge difference={difference} />
                        }
                    </div>
                    {
                        showChevron &&
                        <Icon _icon="ChevronRight" />
                    }
                </div>
            }
            {props.children}
        </div>
    );
};

InfoBox.propTypes = {
    title:       PropTypes.string,
    difference:  PropTypes.number,
    isHeader:    PropTypes.bool,
    children:    PropTypes.node,
    className:   PropTypes.string,
    showChevron: PropTypes.bool
};

export { InfoBox };
