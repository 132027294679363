import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useBranch, useTrigger, useTranslation } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { Icon, OverflowContainer } from "@circle/kip-components";

import styles from "./performanceClusterTable.module.scss";

import { InfoBox } from "../../generic/infoBox/InfoBox";
import { HorizontalBarGraph } from "../../generic/hotizontalBarGraph";
import { BusyIndicator } from "../../BusyIndicator";
import { PerformanceClusterRow } from "./PerformanceClusterRow";
import { usePrevious } from "../../../hooks/previous";

const PerformanceClusterTable = () => { // eslint-disable-line complexity
    const { trigger }                       = useTrigger();
    const { translate }                     = useTranslation();
    const [collapsedRows, setCollapsedRows] = useState({});
    const params                            = useParams();

    const { plant, sorting, isLoading, options, sortedClusterElements } = useBranch({
        plant:                 ["selectedPlant"],
        sorting:               ["sortings", "clusterElements"],
        isLoading:             ["loadingViews", "performanceList"],
        options:               ["queryOptions"],
        sortedClusterElements: ["sortedClusterElements"]
    });

    const optionsPrev = usePrevious(options);

    const toggleRowCollapse = (e, key) => {
        e.stopPropagation();
        e.preventDefault();

        setCollapsedRows(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const onSort = val => {
        trigger("setSorting", "clusterElements", val);
    };

    useEffect(() => {
        trigger("fetchPerformanceList", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, []);

    useEffect(() => {
        if(!optionsPrev) return;

        trigger("fetchPerformanceList", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options.calendar, options.range]);

    const isContentAvailable = sortedClusterElements.length >= 1;

    return (
        <div className={styles.mainContainer}>
            <InfoBox className="noScroll">
                <div className={styles.headerTitle}><b>{translate("dashboard.widgets.overview")}</b></div>
                {
                    isLoading &&
                    <div className={styles.table}>
                        <BusyIndicator />
                    </div>
                }
                {(!isLoading && isContentAvailable) &&
                <div className={styles.table}>
                    <div className={styles.tableHeader}>
                        <div className={resolveClassNames(styles.column, styles.clickable)} onClick={() => onSort("name")}>
                            <div className={styles.iconContainer}>
                                <span>{translate("name.headline")}</span>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "name" &&
                                    <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                                }
                            </div>
                        </div>
                        <div className={resolveClassNames(styles.column, styles.clickable)} onClick={() => onSort("performance")}>
                            <div className={styles.iconContainer}>
                                <span>{translate("overview.performance")}</span>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "performance" &&
                                    <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                                }
                            </div>
                        </div>
                        <div className={resolveClassNames(styles.column, styles.clickable)} onClick={() => onSort("produced")}>
                            <div className={styles.iconContainer}>
                                <span className={styles.regularHeader}>{translate("overview.produced.parts")}</span>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "produced" &&
                                    <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                                }
                            </div>
                        </div>
                        <div className={resolveClassNames(styles.column, styles.clickable)} onClick={() => onSort("avgPartsPerMinute")}>
                            <div className={styles.iconContainer}>
                                <span className={resolveClassNames(styles.regularHeader)}>{translate("performance.average.parts.min")}</span>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "avgPartsPerMinute" &&
                                    <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                                }
                            </div>
                        </div>
                        <div className={resolveClassNames(styles.column, styles.clickable)} onClick={() => onSort("avgCycleTime")}>
                            <div className={styles.iconContainer}>
                                <span className={resolveClassNames(styles.regularHeader)}>{translate("performance.cycle.time.sec")}</span>
                                {
                                    sorting.isOrderAsc !== "hidden" && sorting.property === "avgCycleTime" &&
                                    <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                                }
                            </div>
                        </div>
                    </div>
                    <OverflowContainer className={styles.scrollView}>
                        {sortedClusterElements.filter(x => x.name !== "element.overall").map((item, rowIndex) => (
                            <div key={rowIndex}>
                                <PerformanceClusterRow {...item} ellapsed={collapsedRows[rowIndex]} onEllapse={e => toggleRowCollapse(e, rowIndex)}/>
                                {
                                    item.elements?.map((element, subIndex) => (
                                        <Link key={subIndex} to={`/performance/${plant?.id}/${element.clusterId}/${element.plantElementId}/${window.location.search}`} className={styles.titleLink}>
                                            <div
                                                key={subIndex}
                                                className={resolveClassNames(
                                                    styles.subTable,
                                                    !collapsedRows[rowIndex] && styles.collapsed
                                                )}
                                            >
                                                <div className={styles.subRow}>
                                                    <div className={styles.subColumn}>
                                                        <span>{element.name}</span>
                                                    </div>
                                                    <div className={styles.subColumn} data-columncount={1}>
                                                        <HorizontalBarGraph
                                                            data={{ producedParts: element.produced.current, target: element.produced.target, performance: element.performance }}
                                                            layer={3}
                                                        />
                                                    </div>
                                                    <div className={styles.subColumn} data-columncount={1}>
                                                        <small className={styles.secondLayerTitle}>{element.producedModified?.current}</small>
                                                    </div>
                                                    <div className={styles.subColumn} data-columncount={1}>
                                                        <span className={styles.secondLayerTitle}>{element.avgPartsPerMinuteModified}</span>
                                                    </div>
                                                    <div className={styles.subColumn} data-columncount={1}>
                                                        <b><span className={styles.secondLayerTitle}>{element.avgCycleTimeModified}s</span></b>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </div>
                        ))}
                    </OverflowContainer>
                </div>
                }
                {
                    (!isLoading && !isContentAvailable) &&
                    <div className={styles.table}><b>{translate("overview.no.content")}</b></div>
                }
            </InfoBox>
        </div>
    );
};

export { PerformanceClusterTable };
