interface PlantElement {
    type: string
}

interface Cluster {
    plantElements: PlantElement[]
    measuringElements: PlantElement[]
}

interface KindOptions {
    clusterId: string,
    plantElementId: string
}

interface ClusterOptions {
    type: string
}

export const getClusterType = (cluster: Cluster): string => { // eslint-disable-line complexity
    const types  = cluster.plantElements.map(plantElement => plantElement.type);
    const mTypes = cluster.measuringElements.map(plantElement => plantElement.type);

    const isState   = types.filter(x => x?.includes("States")).length > 0;
    const isCounter = mTypes.filter(x => x?.includes("Counter")).length > 0;
    const isFull    = mTypes.length === mTypes.filter(x => x === "FullCounterAndStates").length;

    if(isState && !isCounter) return "States";
    if(isCounter && !isState) return "Counter";
    if(isState && isCounter && !isFull) return "CounterAndStates";
    if(isState && isCounter && isFull) return "FullCounterAndStates";

    return "empty";
};

// eslint-disable-next-line complexity
export const getConfigOfCluster = (cluster: Cluster) => {
    const clusterType = getClusterType(cluster);

    const config = {
        statesOverall:           !(clusterType === "Counter" || clusterType === "CounterWithType" || clusterType === "FullCounter"),
        targetAmount:            !(clusterType === "States"),
        currentAmount:           !(clusterType === "States"),
        waste:                   !(clusterType === "Counter" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithType" || clusterType === "States" || clusterType === "CounterWithTypeAndStates"),
        throughputTime:          !(clusterType === "Counter" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithType" || clusterType === "States" || clusterType === "CounterWithTypeAndStates"),
        constructionTime:        !(clusterType === "Counter" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithType" || clusterType === "States" || clusterType === "CounterWithTypeAndStates"),
        parts:                   !(clusterType === "States"),
        productivity:            !(clusterType === "States"),
        producedProductTypes:    !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates"),
        historyProduced:         !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates"),
        historyWaste:            !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithTypeAndStates"),
        historyThroughputTime:   !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithTypeAndStates"),
        historyConstructionTime: !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithTypeAndStates"),
        historyParts:            !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates")
    };

    return config;
};

export const getKind = (options: KindOptions) => {
    if(options.clusterId === "null" && options.plantElementId === "null") return "element.overall";
    if(options.clusterId !== "null") return "cluster";

    return "plantElement";
};

export const getType = (kind: string, cluster: ClusterOptions, plantElement: PlantElement) => {
    if(["cluster", "element.overall"].indexOf(kind) > -1) return cluster.type;

    return plantElement?.type || "";
};
