import { Routes, Route } from "react-router-dom";
import { Overview } from "./Overview";
import { DetailView } from "../detail";

const OverviewRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Overview />}/>
            <Route path="/:plantId/*" element={<DetailView />}/>
        </Routes>
    );
};

export { OverviewRouter };
