import { useEffect, useState } from "react";
import { Button, Icon, TextInput, DropDownSelect, OverflowContainer, Modal } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";

import { AdminModal } from "../../adminModal/AdminModal";
import flags from "../../../../enums/flags";

import styles from "./clusterAdministration.module.scss";
import { createPortal } from "react-dom";

const ClusterAdministration = ({ ...props }) => { // eslint-disable-line max-statements, complexity
    const {
        languages,
        language,
        clustersOfPlant,
        sortedPlantElements,
        sorting,
        plant
    } = useBranch({
        sortedPlantElements: ["plantElementsOfPlant"],
        clustersOfPlant:     ["sortedClusterAdminList"],
        languages:           ["languages"],
        language:            ["language"],
        sorting:             ["sortings", "clusterAdministration"],
        plant:               ["selectedPlant"]
    });

    const init = {
        name:   [{ language: "en", content: "" }, { language: "de", content: "" }],
        config: {
            constructionTime:        false,
            currentAmount:           true,
            historyConstructionTime: false,
            historyParts:            false,
            historyProduced:         false,
            historyThroughputTime:   false,
            historyWaste:            false,
            parts:                   true,
            producedProductTypes:    false,
            productivity:            true,
            statesOverall:           false,
            targetAmount:            true,
            throughputTime:          false,
            waste:                   false
        },
        isSequentiallyOrdered: false,
        isSortedOutSumup:      false,
        isStatesSumup:         false,
        measuringElements:     [],
        plantElements:         [],
        plantId:               plant?.id
    };

    const { trigger }                           = useTrigger();
    const { translate }                         = useTranslation();
    const [content, setContent]                 = useState(init);
    const [modal, setModal]                     = useState(false);
    const [languageValue, setLanguageValue]     = useState(language);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [toDelete, setToDelete]               = useState("");
    const [valid, setValid]                     = useState({});

    const validate = () => ({
        isValid: true
    });

    const validateRequired = cont => {
        return languages.reduce((dest, val) => dest && cont?.name.find(x => x.language === val.value)?.content.length > 0, true) && cont.plantElements.length > 0;
    };

    const onSort = property => {
        trigger("setSorting", "clusterAdministration", property);
    };

    const languageOptions = languages.map((x, key) => ({
        value:   x.value,
        label:   <span key={key} className={styles.local}><Icon _icon={flags[x.value]} style={{ fontSize: "1.5rem", marginRight: "0.5rem" }} /> {x.label}</span>,
        checked: languageValue === x.value
    }));

    const onClusterSettings = (e, id) => {
        e.stopPropagation();
        const selectedCluster = clustersOfPlant?.find(x => x.id === id);

        setModal(true);
        setContent(selectedCluster);
    };

    const plantElementsValues          = content.plantElements?.map(x => x.plant_element_id);
    const measuringPlantElementValues  = content.measuringElements?.map(x => x.plant_element_id);

    const plantElementsOptions = sortedPlantElements
        .map((x, key) => ({
            value:   x.id || "",
            checked: plantElementsValues?.includes(x.id),
            label:   <span key={key}>
                <span>
                    <b>{translate(x.name) || ""}</b>
                </span>
            </span>
        })) || [];

    const measuringPlantElementsOptions = sortedPlantElements
        .filter(elem => plantElementsValues?.includes(elem.id))
        .map((x, key) => ({
            value:   x.id || "",
            checked: measuringPlantElementValues?.includes(x.id),
            label:   <span key={key}>
                <span>
                    <b>{translate(x.name) || ""}</b>
                </span>
            </span>
        })) || [];

    const onLanguageChange = lang => {
        const updateLanguage = lang.filter(x => x.checked === true);

        setLanguageValue(updateLanguage[0].value);
        trigger("changeLanguage", updateLanguage[0].value);
    };

    const onInputChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };

        setContent(values);
    };

    const onTranslationChange = (key, value) => {
        const current = content[key] ? [...content[key]] : [];
        const index   = current.findIndex(elem => elem.language === language);
        const item    = current[index];

        if(!item) return onInputChange(key, current.concat({
            language: language,
            content:  value
        }));

        current.splice(index, 1, {
            ...item,
            content: value
        });

        return onInputChange(key, current);
    };

    const onSelectPlantElements = (name, options) => {
        const selectedIds = options
            .filter(x => x.checked)
            .map(x => ({
                plant_element_id: x.value // eslint-disable-line camelcase
            }));

        onInputChange(name, selectedIds);
    };

    const onClose = () => {
        setContent(init);
        props.onCancel();
        setModal(false);
    };

    const onDeleteModal = (e, id) => {
        e.stopPropagation();
        setToDelete(id);
        setShowDeleteModal(!showDeleteModal);
    };

    const onDelete = () => {
        trigger("deleteCluster", toDelete);
        setShowDeleteModal(!showDeleteModal);
    };

    const onSave = () => {
        if(!valid.overall)  return;

        if(props.isCreate) {
            trigger("createCluster", content);
            setModal(false);
            props.onCancel();
            return;
        }

        trigger("editCluster", content);
        setContent(init);
        setModal(false);
        return;
    };

    useEffect(() => {
        trigger("setSorting", "clusterAdministration", "name");
    }, []);

    useEffect(() => {
        setModal(props.isCreate);
        setContent(init);
    }, [props.isCreate]);

    useEffect(() => {
        const required  = validateRequired(content);
        const validated = validate();
        const overall   = required && validated.isValid;

        setValid({
            required,
            overall
        });
    }, [content]);

    return (
        <div className={styles.mainContainer}>
            <table className={styles.messageTable}>
                <thead>
                    <tr>
                        <th style={{ flex: 1 }} className={styles.clickable} onClick={() => onSort("name")}>
                            <small className={styles.clickable}>{ translate("element.name.header") }</small>
                            {
                                sorting.isOrderAsc !== "hidden" && sorting.property === "name" &&
                                <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                            }
                        </th>
                        <th style={{ flex: 1 }} onClick={() => onSort("plantElements")} className={styles.clickable}>
                            <small>{ translate("plant.ele.count.header") }</small>
                            {
                                sorting.isOrderAsc !== "hidden" && sorting.property === "plantElements" &&
                                <Icon className={styles.icon} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"}/>
                            }
                        </th>
                        <th style={{ flex: 1 }} className={styles.textCenter} />
                    </tr>
                </thead>
                <tbody>
                    {
                        clustersOfPlant?.length === 0 &&
                        <tr className={styles.noContent}>
                            <td className={styles.noContent}>{translate("overview.no.content")}</td>
                        </tr>
                    }
                    {
                        clustersOfPlant.map((x, key) => (
                            <tr key={key} className={styles.rowContainer}>
                                <td style={{ flex: 1 }}><span>{ translate(x.name) }</span></td>
                                <td style={{ flex: 1 }}><span>{ x.plantElements.length }</span></td>
                                <td style={{ flex: 1 }}>
                                    <span className={styles.textRight}>
                                        <Button className={styles.button} _variant="icon" onClick={e => onClusterSettings(e, x.id)}>
                                            <Icon _icon="Edit"/>
                                        </Button>
                                        <Button className={styles.button} _variant="icon" onClick={e => onDeleteModal(e, x.id)}>
                                            <Icon _icon="Trashcan"/>
                                        </Button>
                                    </span>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {
                props.modalRef.current &&
                createPortal(
                    <AdminModal
                        title={translate("cluster.info.edit")}
                        isOpen={modal}
                    >
                        <div className={styles.modalContainer}>
                            <OverflowContainer className={styles.overflow}>
                                <div className={styles.contentContainer}>
                                    <span className={styles.inputLabel}>{translate("name.headline")}*</span>
                                    <TextInput className={resolveClassNames("full-width")} value={translate(content?.name, language)} onChange={e => onTranslationChange("name", e.target.value)}/>
                                    <div className={styles.inputContainer} />

                                    <span className={styles.inputLabel}>{translate("plant.elem.header")}*</span>
                                    <DropDownSelect
                                        id="plantElement"
                                        value={plantElementsValues}
                                        onChange={opt => onSelectPlantElements("plantElements", opt)}
                                        isEnabled={true}
                                        triggerOnChangeOnOptionsChange={false}
                                        options={plantElementsOptions}
                                        className={resolveClassNames("full-width")}
                                        isMulti={true}
                                    />

                                    <div className={styles.divider} />

                                    <span className={styles.inputLabel}>{translate("counting.system.elements")}</span>
                                    <div className={styles.elementSelectTextContainer}>
                                        <small className={styles.descriptionText}>
                                            {translate("cluster.elements.first.part.tip")}
                                        </small>{" "}
                                        <small className={styles.descriptionText}>
                                            {translate("cluster.elements.second.part.tip")}
                                        </small>
                                    </div>
                                    <DropDownSelect
                                        id="measuringElement"
                                        value={measuringPlantElementValues}
                                        onChange={opt => onSelectPlantElements("measuringElements", opt)}
                                        isEnabled={true}
                                        triggerOnChangeOnOptionsChange={false}
                                        options={measuringPlantElementsOptions}
                                        className={resolveClassNames("full-width")}
                                        isMulti={true}
                                        isEmptyValueAllowed={true}
                                    />
                                </div>
                            </OverflowContainer>
                            <div className={styles.modalFooter}>
                                <div className={styles.dropdownContainer}>
                                    <span>
                                        <DropDownSelect
                                            // className={styles.dropdownSelect}
                                            options={languageOptions}
                                            isEmptyValueAllowed={false}
                                            isCustomInputEnabled={false}
                                            closeOnOptionClick
                                            onChange={onLanguageChange}
                                            required
                                        />
                                    </span>
                                </div>
                                <div>
                                    <Button className={styles.footerButton} onClick={onClose}>
                                        { translate("actions.cancel") }
                                    </Button>
                                    <Button className={styles.footerButton} disabled={!valid.overall} onClick={onSave} _appearance="primary">
                                        { translate("actions.save") }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </AdminModal>,
                    props.modalRef.current
                )
            }
            <Modal
                isOpen={showDeleteModal}
                isCloseable={false}
                title={translate("modal.delete.danger")}
            >
                <div className={styles.modal}>
                    <span>{ translate("modal.delete.really") }</span>
                    <div>
                        <Button _appearance="primary" onClick={() => setShowDeleteModal(!showDeleteModal)}>{translate("actions.no")}</Button>
                        <Button onClick={onDelete}>{translate("actions.yes")}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

ClusterAdministration.propTypes = {
    isCreate: PropTypes.bool,
    onCancel: PropTypes.func,
    modalRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
};

export { ClusterAdministration };
