import { useState } from "react";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { SwitchButton } from "@circle/kip-components";

import { InfoBox } from "../../generic/infoBox/InfoBox";
import { FrequencyTable } from "./frequencyTable";
import { DurationTable } from "./durationTable";
import { ChronologyTable } from "./chronologyTable";

import styles from "./eventsStatisticsTable.module.scss";
import { BusyIndicator } from "../../BusyIndicator";

const EventsStatisticsTable = () => {
    const [filter, setFilter] = useState("frequency");
    const { translate }       = useTranslation();
    const { isLoading }       = useBranch({
        isLoading: ["loadingViews", "eventsTable"]
    });

    const onSelect = e => {
        setFilter(e.target.value);
    };

    return (
        <div className={styles.container}>
            <InfoBox className="noScroll">
                <div className={styles.switchButtonContainer}>
                    <SwitchButton
                        className={styles.filter}
                        onChange={onSelect}
                        options={[
                            {
                                value:   "frequency",
                                checked: filter === "frequency",
                                label:   translate("overview.filter.frequency")
                            },
                            {
                                value:   "availability",
                                checked: filter === "availability",
                                label:   translate("overview.filter.duration")
                            },
                            {
                                value:   "chronologically",
                                checked: filter === "chronologically",
                                label:   translate("events.chronologically")
                            }
                        ]}
                    />
                </div>
                <>
                    {
                        isLoading &&
                        <BusyIndicator />
                    }
                    { filter === "frequency" && <FrequencyTable />}
                    { filter === "availability" && <DurationTable />}
                    { filter === "chronologically" && <ChronologyTable />}
                </>
            </InfoBox>
        </div>
    );
};

export { EventsStatisticsTable };
