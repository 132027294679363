import { useEffect, useState } from "react";
import { DatePicker } from "../types/DatePicker";
import { useParams } from "react-router-dom";
import { useTrigger } from "@circle/gestalt-app";

const useQueryString = ({ plants, options, optional = {} }) => {
    const { trigger }         = useTrigger();
    const params              = useParams();
    const [filter, setFilter] = useState({});

    useEffect(() => {
        const urlParts = decodeURI(window.location.href).split("?");

        const filters  = urlParts.length < 2 ? {} : urlParts[1].split("&").reduce((dest, val) => Object.assign({}, dest, {
            [val.split("=")[0]]: val.split("=")[1]
        }), {});

        const timeParts    = filters?.startTime?.substring(1, filters.startTime.length - 1).split(",") ?? [0, 0];
        const startTime    = DatePicker.of([new Date(parseInt(timeParts[0], 10) * 1000), new Date(parseFloat(timeParts[1], 10) * 1000)]);

        const activeFilter = {
            range:         (filters.range ?? "").replace("undefined", "") || options.range || "last7Days",
            selectedPlant: plants.find(x => x.id === params.plantId),
            calendar:      filters?.startTime ? startTime : options.calendar,
            ...Object.keys(optional).reduce((dest, x) => ({ ...dest, [x]: optional[x](filters[x]) }), {})
        };

        trigger("setQueryOptions", activeFilter);
        setFilter(activeFilter);
    }, []);

    return [filter];
};

export { useQueryString };
