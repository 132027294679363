import { useEffect } from "react";
import PropTypes from "prop-types";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { Menu } from "./Menu";
import { Content } from "./Content";
import { Root as CompRoot, rootClassName } from "@circle/kip-components";
import { SidebarHost } from "@circle/kip-components";
import { Router } from "./Router";

const Root = ({ ...props }) => {
    const { trigger }       = useTrigger();
    const { init, history } = useBranch({
        init:    ["init"],
        history: ["history"]
    });

    useEffect(() => {
        document.getElementById("default-loading-anchor")?.remove();
        const interval = setInterval(() => {
            trigger("setClock", Date.now());
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Router history={history} id={props.id}>
            <CompRoot>
                {
                    init &&
                    <div className={`flex ${rootClassName}`}>
                        <SidebarHost>
                            <Menu/>
                            <div id="page-content" className={history.location.pathname.startsWith("/favorite") ? "favorite-content-page content-padding" : "page-content content-padding"}>
                                {
                                    init &&
                                    <Content/>
                                }
                            </div>
                        </SidebarHost>
                    </div>
                }
            </CompRoot>
        </Router>
    );
};

Root.propTypes = {
    id: PropTypes.string
};

export { Root };
