import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./horizontalBarGraph.module.scss";

const HorizontalBarGraph = ({ data = { producedParts: 0, target: 0 } }) => {
    const performance = data.performance || 0;

    return (
        <div className={styles.progressLine}>
            <div className={styles.container}>
                <div className={resolveClassNames(styles.progress, styles.producedParts)} style={{ width: `${performance <= 100 ? performance : 100}%` }} />
                <div className={resolveClassNames(styles.progress, styles.producedPartsTarget)} style={{ width: `${100 - (performance <= 100 ? performance : 100)}%` }} />
                {
                    performance > 0 && data.target > 0 &&
                    <span className={styles.performance}>{`${performance}%`}</span>
                }
            </div>
        </div>
    );
};

HorizontalBarGraph.propTypes = {
    data: PropTypes.object
};

export { HorizontalBarGraph };
