import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePrevious } from "../../hooks/previous";
import { OverflowContainer } from "@circle/kip-components";

const InfiniteList = ({ // eslint-disable-line complexity
    className = "",
    onFetch = x => x,
    element = "div",
    loader = () => <div>Lalilu</div>,
    placeholder = <div />,
    metadata = { limit: 10, count: -1, page: 1 },
    ...props
}) => {
    const ref = useRef();
    const wrapper = useRef();
    const prevCount = usePrevious(props.children.length);

    const [blocked, setBlocked] = useState(false);

    const hasMore = props.children.length > 0 &&
        (metadata.limit * metadata.page) < metadata.count &&
        !blocked;

    const Element = typeof element === "string" || element instanceof String ? element : element.type;
    const _loader = loader();

    const isScrolledToEnd = () => {
        if(!wrapper.current || !ref.current) return false;

        const { scrollTop, scrollHeight, clientHeight } = wrapper.current;

        return scrollTop + clientHeight >= scrollHeight - 10;
    };

    const handleScroll = () => {
        if(isScrolledToEnd() && hasMore && !blocked) {
            setBlocked(true);
            onFetch();
        }
    };

    useEffect(() => {
        const currentWrapper = wrapper.current;

        currentWrapper.addEventListener("scroll", handleScroll);

        return () => {
            currentWrapper.removeEventListener("scroll", handleScroll);
        };
    }, [blocked, hasMore]);

    useEffect(() => {
        if(props.children.length > prevCount)
            setBlocked(false);
    }, [props.children.length, prevCount]);

    return (
        <OverflowContainer className={className} ref={wrapper}>
            <Element>
                {props.children.length > 0 && props.children}
                {props.children.length <= 0 && placeholder}
                {blocked && <_loader.type>{_loader.props.children}</_loader.type>}
                {hasMore && !blocked && <_loader.type ref={ref}>{_loader.props.children}</_loader.type>}
            </Element>
        </OverflowContainer>
    );
};

InfiniteList.propTypes = {
    children:    PropTypes.node,
    metadata:    PropTypes.object,
    loader:      PropTypes.func,
    element:     PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onFetch:     PropTypes.func,
    className:   PropTypes.string,
    placeholder: PropTypes.object
};

export { InfiniteList };
