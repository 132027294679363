import { Routes, Route } from "react-router-dom";
import { useBranch, useTrigger, useTranslation } from "@circle/gestalt-app";

import { Breadcrumb } from "./generic/Breadcrumb/Breadcrumb";
import { RangeSelector } from "./generic/rangeSelector/RangeSelector";
import { AdminHeader } from "./settings/adminHeader/AdminHeader";
import { FavoriteViewHeader } from "./favoriteView/favoriteViewHeader/FavoriteViewHeader";
import { ClusterFavoriteViewHeader } from "./favoriteView/clusterFavoriteView/clusterFavoriteViewHeader";
import { Export } from "./generic/Export";
import { Button } from "@circle/kip-components";

const ContentHeader = () => { // eslint-disable-line complexity
    const { trigger } = useTrigger();
    const { translate } = useTranslation();
    const { options } = useBranch({
        options: ["queryOptions"]
    });

    const onDatePick = date => {
        trigger("onCalendarSelect", date);
    };

    return (
        <div className="vertical-align" style={{ minHeight: "4rem" }}>
            <Routes>
                <Route path="/favorite" element={<FavoriteViewHeader />}/>
                <Route path="/favorite/:plantId/:clusterId/:plantElementId/*" element={<ClusterFavoriteViewHeader />}/>
                <Route path="/admin/*" element={<AdminHeader />}/>
                <Route
                    path="/overview"
                    element={
                        <div className="header">
                            <Breadcrumb />
                            <RangeSelector
                                range={options.range}
                                selected={options.calendar}
                                onChange={onDatePick}
                                isOverview={true}
                            />
                        </div>
                    }
                />
                <Route
                    path="/overview/*"
                    element={
                        <div className="header">
                            <Breadcrumb items={[{ value: translate(options.selectedPlant?.name || ""), path: "", index: 0 }]} />
                            <div className="subHeader">
                                <a href={`${window.location.href.replace(window.location.host, `${window.location.host.replace("performance.", "")}/message-monitor`)}`} target="_blank" rel="noreferrer">
                                    <Button>{translate("to.message.monitor")}</Button>
                                </a>
                                <Export />
                                <RangeSelector
                                    range={options.range}
                                    selected={options.calendar}
                                    onChange={onDatePick}
                                />
                            </div>
                        </div>
                    }
                />
                <Route
                    path="/runtime/:plantId"
                    element={
                        <div className="header">
                            <Breadcrumb
                                items={[
                                    { value: translate(options.selectedPlant?.name || ""), path: `/overview/${options.selectedPlant?.id}`, index: 0 },
                                    { value: translate("detail.duration"), path: `/runtime/${options.selectedPlant?.id}`, index: 1 }
                                ]}
                            />
                        </div>
                    }
                />
                <Route
                    path="/runtime/:plantId/:clusterId/:plantElementId/"
                    element={
                        <div className="header">
                            <Breadcrumb
                                items={[
                                    { value: translate(options.selectedPlant?.name || ""), path: `/overview/${options.selectedPlant?.id}`, index: 0 },
                                    { value: translate("detail.duration"), path: `/runtime/${options.selectedPlant?.id}`, index: 1 },
                                    { value: translate(options.selectedPlantElement?.name ? options.selectedPlantElement?.name : options.selectedCluster?.name), path: `/runtime/${options.selectedPlant?.id}/${options.cluster}`, index: 2 }
                                ]}
                            />
                        </div>
                    }
                />
                <Route
                    path="/performance/:plantId"
                    element={
                        <div className="header">
                            <Breadcrumb
                                items={[
                                    { value: translate(options.selectedPlant?.name || ""), path: `/overview/${options.selectedPlant?.id}`, index: 0 },
                                    { value: translate("overview.performance"), path: `/performance/${options.selectedPlant?.id}`, index: 1 }
                                ]}
                            />
                        </div>
                    }
                />
                <Route
                    path="/performance/:plantId/:clusterId/:plantElementId/"
                    element={
                        <div className="header">
                            <Breadcrumb
                                items={[
                                    { value: translate(options.selectedPlant?.name || ""), path: `/overview/${options.selectedPlant?.id}`, index: 0 },
                                    { value: translate("overview.performance"), path: `/performance/${options.selectedPlant?.id}`, index: 1 },
                                    { value: translate(options.selectedPlantElement?.name ? options.selectedPlantElement?.name : options.selectedCluster?.name), path: `/performance/${options.selectedPlant?.id}/${options.cluster}`, index: 2 }
                                ]}
                            />
                        </div>
                    }
                />
                <Route
                    path="/events/*"
                    element={
                        <div className="header">
                            <Breadcrumb
                                items={[
                                    { value: translate(options.selectedPlant?.name || ""), path: `/overview/${options.selectedPlant?.id}`, index: 0 },
                                    { value: translate("dashboard.widgets.events"), path: `/events/${options.selectedPlant?.id}`, index: 1 }
                                ]}
                            />
                        </div>
                    }
                />
            </Routes>
        </div>
    );
};

export { ContentHeader };
