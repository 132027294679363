import { Routes, Route } from "react-router-dom";
import { PerformanceOverview } from "../PerformanceOverview";
import { ClusterRuntimeOverview } from "../../runtimeOverview/clusterRuntimeOverview/ClusterRuntimeOverview";

const PerformanceRouter = () => {
    return (
        <Routes>
            <Route path="/:plantId/*" element={<PerformanceOverview />} />
            <Route path="/:plantId/:clusterId/:plantElementId/*" element={<ClusterRuntimeOverview />} />
        </Routes>
    );
};

export { PerformanceRouter };
