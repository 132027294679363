import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Link } from "react-router-dom";
import { Icon } from "@circle/kip-components";

import styles from "./headerPieGraph.module.scss";
import { numberFormatter } from "../../../helper/numberFormatter";

const HeaderPieGraph = ({ ...props }) => { // eslint-disable-line complexity
    const { translate }      = useTranslation();
    const { options, plant } = useBranch({
        options: ["queryOptions"],
        plant:   ["selectedPlant"]
    });

    const COLORS = [
        { errors: "#EB6060" },
        { organizationals: "#F3C173" },
        { maintenance: "#5EAEFF" },
        { setup: "#D5E2BF" },
        { notReady: "#FF9966" },
        { blocked: "#F7E463" },
        { standstill: "#A8A8A8" },
        { used: "#7CD992" }
    ];

    const pieDataWithColors = props.pieData?.map(item => ({
        ...item,
        color: COLORS.find(color => color[item.name])?.[item.name]
    })) || [];

    const subDataWithColors = props.subData?.map(item => ({
        ...item,
        color: COLORS.find(color => color[item.name])?.[item.name]
    })) || [];

    const data = pieDataWithColors[0] ? pieDataWithColors.concat(subDataWithColors) : [];

    const getRender = elem => {
        if(!elem.selectedCluster?.id && elem.selectedPlantElement) return translate(elem.selectedPlantElement?.name);

        if(elem.selectedCluster?.id) return translate(elem.selectedCluster?.name);

        return translate("system.headline");
    };

    return (
        props.linkDirection ?
            <Link to={`/${props.linkDirection}/${plant?.id}/null/null/${window.location.search}`} className={styles.titleLink}>
                <div className={resolveClassNames(styles.card, styles.link)}>
                    <div className={styles.container}>
                        <div>
                            <span className={styles.header}><b>{translate("detail.duration")}</b>{getRender(options)}</span>
                            <div className={styles.timeStatisticContainer}>
                                <div className={styles.item}>
                                    <span className={resolveClassNames(styles.header)}>{props.overviewData?.runtime?.duration > 0 ? <><b className={styles.timeTitle}>{props.overviewData?.techAvailability}</b> %</> : <b className={styles.timeTitle}>-</b>}</span>
                                    <div className={styles.underTitle}>
                                        <small className={styles.small}>
                                            {translate("overview.tile.technical.availability")}
                                        </small>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <span className={resolveClassNames(styles.header)}>{props.overviewData?.runtime?.duration > 0 ? <><b className={styles.timeTitle}>{props.overviewData?.availability}</b> %</> : <b className={styles.timeTitle}>-</b>}</span>
                                    <div className={styles.underTitle}>
                                        <small className={styles.small}>
                                            {translate("overview.tile.availability")}
                                        </small>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.header}><b className={styles.timeTitle}>{numberFormatter(props.overviewData?.runtime.duration, 999999) || 0}</b> {translate("overview.minutes.short")}</span>
                                    <div className={styles.underTitle}>
                                        <small className={styles.small}>
                                            {translate("dashboard.widgets.total.run.time")}
                                        </small>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.header}><b className={styles.timeTitle}>{numberFormatter(props.overviewData?.outOfService, 999999) || 0}</b> {translate("overview.minutes.short")}</span>
                                    <div className={styles.underTitle}>
                                        <small className={styles.small}>
                                            {translate("overview.tile.under.maintenance")}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.verticalLine} />

                        <div className={styles.chartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={5} height={5}>
                                    <Pie
                                        data={props.pieData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={13}
                                        outerRadius={30}
                                        dataKey="value"
                                    >
                                        {pieDataWithColors.map((entry, index) => (
                                            <Cell key={index} fill={entry.color} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>

                        { data.map((elem, i) => (
                            <div key={i} className={styles.messageContainer}>
                                <div className={(i >= 3) ? resolveClassNames(styles.messageTitleContainer, styles.relations) : styles.messageTitleContainer}>
                                    <div className={resolveClassNames(styles.circle, styles[elem.name])} />
                                    <span className={resolveClassNames(styles.small, styles.messageTitle)}>{translate(`${elem.name}`)}</span>
                                </div>
                                <div className={styles.subtitleContainer}>
                                    <span><b>{numberFormatter(elem.value, 999999) ?? 0}</b> <small className={styles.small}>{translate("overview.minutes.short")}</small></span>
                                    <span><b>{elem.portion ?? 0}</b> <small className={styles.small}>%</small></span>
                                </div>
                            </div>
                        ))}
                        <Icon className={styles.chevron} _icon="ChevronRight" />
                    </div>
                </div>
            </Link> :
            <div className={styles.card}>
                <div className={styles.container}>
                    <div>
                        <span className={styles.header}><b>{translate("detail.duration")}</b>{getRender(options)}{props.linkDirection && <Icon _icon="ChevronRight" />}</span>
                        <div className={styles.timeStatisticContainer}>
                            <div className={styles.item}>
                                <span className={resolveClassNames(styles.header)}><b className={styles.timeTitle}>{props.overviewData?.techAvailability || 0}</b> %</span>
                                <div className={resolveClassNames(styles.underTitle, styles.multiline)}>
                                    <small className={styles.small}>
                                        {translate("overview.tile.technical.availability")}
                                    </small>
                                </div>
                            </div>
                            <div className={styles.item}>
                                <span className={resolveClassNames(styles.header)}><b className={styles.timeTitle}>{props.overviewData?.availability || 0}</b> %</span>
                                <div className={styles.underTitle}>
                                    <small className={styles.small}>
                                        {translate("overview.tile.availability")}
                                    </small>
                                </div>
                            </div>

                            <div className={styles.item}>
                                <span className={styles.header}><b className={styles.timeTitle}>{numberFormatter(props.overviewData?.runtime.duration, 999999) || 0}</b> {translate("overview.minutes.short")}</span>
                                <div className={styles.underTitle}>
                                    <small className={styles.small}>
                                        {translate("dashboard.widgets.total.run.time")}
                                    </small>
                                </div>
                            </div>

                            <div className={styles.item}>
                                <span className={styles.header}><b className={styles.timeTitle}>{numberFormatter(props.overviewData?.outOfService, 999999) || 0}</b> {translate("overview.minutes.short")}</span>
                                <div className={styles.underTitle}>
                                    <small className={styles.small}>
                                        {translate("overview.tile.under.maintenance")}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.verticalLine} />

                    <div className={styles.chartContainer}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={5} height={5}>
                                <Pie
                                    data={props.pieData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={13}
                                    outerRadius={30}
                                    dataKey="value"
                                >
                                    {pieDataWithColors.map((entry, index) => (
                                        <Cell key={index} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    { data.map((elem, i) => (
                        <div key={i} className={styles.messageContainer}>
                            <div className={(i >= 3) ? resolveClassNames(styles.messageTitleContainer, styles.relations) : styles.messageTitleContainer}>
                                <div className={resolveClassNames(styles.circle, styles[elem.name])} />
                                <span className={resolveClassNames(styles.small, styles.messageTitle)}>{translate(`${elem.name}`)}</span>
                            </div>
                            <div className={styles.subtitleContainer}>
                                <span><b>{numberFormatter(elem.value, 999999) ?? 0}</b> <small className={styles.small}>{translate("overview.minutes.short")}</small></span>
                                <span><b>{elem.portion ?? 0}</b> <small className={styles.small}>%</small></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    );
};

HeaderPieGraph.propTypes = {
    pieData:       PropTypes.array,
    subData:       PropTypes.array,
    overviewData:  PropTypes.object,
    linkDirection: PropTypes.string
};

export { HeaderPieGraph };
