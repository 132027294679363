import { useEffect } from "react";
import { useTranslation, useBranch, useTrigger } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { BarGraph } from "../../generic/barGraph";
import { InfoBox } from "../../generic/infoBox/InfoBox";
import { StatisticsList } from "./statisticsList";
import { numberFormatter } from "../../../helper/numberFormatter";
import { BusyIndicator } from "../../BusyIndicator";

import styles from "./durationContent.module.scss";


const DurationContent = ({ ...props }) => { // eslint-disable-line complexity
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();

    const { content, options, isLoading } = useBranch({
        content:   ["durationContent"],
        options:   ["queryOptions"],
        isLoading: ["loadingViews", "durationContent"]
    });

    const chartData = {
        errors:          content.states?.errors?.duration * 100 || null,
        organizationals: content.states?.organizationals?.duration * 100 || null,
        used:            content.states?.used?.duration * 100 || null
    };

    const statesList = content.states || {};

    const isContentAvailable = (content.runtime?.duration > 0 || content.outOfService > 0) || false;

    useEffect(() => {
        trigger("fetchDurationContent", { plantId: props.plantId });
    }, [options.calendar]);

    useEffect(() => {
        trigger("fetchDurationContent", { plantId: props.plantId });

        const interval = setInterval(() => trigger("fetchDurationContent", { plantId: props.plantId, polling: true }), 30000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {
                isLoading &&
                <div className={styles.item}>
                    <InfoBox
                        isHeader={true}
                        difference={content.runtime?.tendency || null}
                        title={translate("detail.duration")}
                        className={"isSmallWidth"}
                        showChevron={isContentAvailable}
                    >
                        <BusyIndicator />
                    </InfoBox>
                </div>
            }
            {
                (!isLoading && isContentAvailable) &&
                <Link className={styles.item} to={`/runtime/${props.plantId}`}>
                    <InfoBox
                        isHeader={true}
                        difference={content.runtime?.tendency || null}
                        title={translate("detail.duration")}
                        className={"isSmallWidth"}
                        showChevron={isContentAvailable}
                    >
                        {
                            (isContentAvailable && !isLoading) &&
                            <>
                                <div className={styles.header}>
                                    <div>
                                        <div className={styles.statisticContainer}>
                                            {
                                                content.runtime?.duration > 0 &&
                                                <>
                                                    <span className={resolveClassNames(styles.statisticTitle)}>{numberFormatter(content.techAvailability || 0, 999999)}</span>
                                                    <span className={resolveClassNames(styles.statisticTitleDescription)}>%</span>
                                                </>
                                            }
                                            {
                                                content.runtime?.duration <= 0 &&
                                                <span className={styles.statisticTitle}>-</span>
                                            }
                                        </div>
                                        <small>{translate("overview.tile.technical.availability")}</small>
                                    </div>
                                    <div>
                                        <div className={styles.statisticContainer}>
                                            {
                                                content.runtime?.duration > 0 &&
                                                <>
                                                    <span className={resolveClassNames(styles.statisticTitle)}>{numberFormatter(content.availability || 0, 999999)}</span>
                                                    <span className={resolveClassNames(styles.statisticTitleDescription)}>%</span>
                                                </>
                                            }
                                            {
                                                content.runtime?.duration <= 0 &&
                                                <span className={styles.statisticTitle}>-</span>
                                            }
                                        </div>
                                        <small>{translate("overview.tile.availability")}</small>
                                    </div>

                                    <div>
                                        <div className={styles.statisticContainer}>
                                            <span className={styles.statisticTitle}>{content.runtime ? numberFormatter(content.runtime?.duration, 9999999) : 0}</span>
                                            <span className={styles.statisticTitleDescription}>{translate("overview.minutes.short")}</span>
                                        </div>
                                        <small>{translate("overview.duration")}</small>
                                    </div>

                                    <div>
                                        <div className={styles.statisticContainer}>
                                            <span className={styles.statisticTitle}>{numberFormatter(content.outOfService, 9999999)}</span>
                                            <span className={styles.statisticTitleDescription}>{translate("overview.minutes.short")}</span>
                                        </div>
                                        <small>{translate("overview.tile.under.maintenance")}</small>
                                    </div>
                                </div>
                                <div className={styles.contentContainer}>
                                    <BarGraph data={chartData}/>
                                    <StatisticsList data={statesList} translate={translate}/>
                                </div>
                            </>
                        }
                    </InfoBox>
                </Link> }
            {
                (!isLoading && !isContentAvailable) &&
                <div className={styles.item}>
                    <InfoBox
                        isHeader={true}
                        difference={content.runtime?.tendency || null}
                        title={translate("detail.duration")}
                        className={"isSmallWidth"}
                        showChevron={isContentAvailable}
                    >
                        { (!isContentAvailable && !isLoading) && <div className={styles.noContent}>
                            <span>{translate("overview.no.content")}</span>
                        </div> }
                    </InfoBox>
                </div>
            }
        </>
    );
};

DurationContent.propTypes = {
    plantId: PropTypes.string
};


export { DurationContent };
