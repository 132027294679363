
import PropTypes from "prop-types";
import { ProgressBar } from "../../generic/progressBar/ProgressBar";
import { useTranslation } from "@circle/gestalt-app";

import styles from "./messageTypeProgress.module.scss";

const MessageTypeProgress = ({ ...props }) => {
    const { translate } = useTranslation();

    return (
        <div className={styles.progress}>
            {
                !props.hideBar &&
                <ProgressBar className={styles[`progress-${props.type}`]} isHeightSmall={props.layer === 2} value={props.isCount ? (props.ratio ?? 0) : (props.ratio ?? 0) * 100}/>
            }
            <div className={styles.tendency}>
                {!props.hideBar ?
                    <div className={styles.content}>
                        <b className={props.layer === 1 ? styles.mainTitle : styles.mainTitleSmall}>{props.duration}</b>
                        {" "}
                        <span className={props.layer === 1 ? styles.subTitle : styles.subTitleSmall}>{translate("overview.minutes.short")}</span>
                    </div> :
                    <div className={styles.content}>
                        <b>{props.duration}</b>
                        {" "}
                        <span className={styles.subTitleHeader}>{translate("overview.minutes.short")}</span>
                    </div>
                }
            </div>
        </div>
    );
};

MessageTypeProgress.propTypes = {
    ratio:    PropTypes.number,
    isCount:  PropTypes.bool,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type:     PropTypes.string,
    hideBar:  PropTypes.bool,
    layer:    (props, propName, componentName) => {
        if(props[propName] !== 1 && props[propName] !== 2)
            console.error(`Invalid ${propName} value in ${componentName}. Expected 1 or 2, other functionalities has not been implemented}.`);
    }
};

export { MessageTypeProgress };
