import { Routes, Route } from "react-router-dom";

import { FavoriteView } from "../FavoriteView";
import { ClusterFavoriteView } from "../clusterFavoriteView/ClusterFavoriteView";

const FavoriteRouter = () => {
    return (
        <Routes>
            <Route path="/*" element={<FavoriteView />} />
            <Route path="/:plantId/:clusterId/:plantElementId/*" element={<ClusterFavoriteView />} />
        </Routes>
    );
};

export { FavoriteRouter };
