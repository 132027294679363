import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";

import { DurationContent } from "./durationContent/DurationContent";
import { PerformanceContent } from "./performanceContent/PerformanceContent";
import { EventsContent } from "./eventsContent/EventsContent";
import { PlantOverviewHeader } from "../generic/plantOverviewHeader/PlantOverviewHeader";

import styles from "./detailView.module.scss";

const DetailView = () => {
    const { trigger }                    = useTrigger();
    const params                         = useParams();
    const { translate, currentLanguage } = useTranslation();
    const { plant, options, plants }     = useBranch({
        plant:   ["selectedPlant"],
        options: ["queryOptions"],
        plants:  ["fullPlants"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   plants,
        optional: {
            range:    x => (x ?? "").replace("undefined", "") || options.range || "last7Days",
            order:    () => options.order || "asc",
            orderKey: () => options.orderKey || "name"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    useEffect(() => {
        trigger("applyPlant", params.plantId);

        return () => trigger("clearMemory");
    }, []);

    return (
        <>
            {
                init &&
                <div className={styles.detailContainer}>
                    <PlantOverviewHeader
                        plant={plant}
                        options={options}
                        currentLanguage={currentLanguage}
                        translate={translate}
                    />
                    <div className={styles.header}>
                        <span>{translate("detail.plant.efficiency")}</span>
                    </div>
                    <div className={styles.content}>
                        {
                            plant &&
                            <>
                                <DurationContent plantId={plant.id} />
                                <PerformanceContent plantId={plant.id} />
                                <EventsContent plantId={plant.id} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export { DetailView };
